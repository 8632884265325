<template>
	<div
		class="view-content__wrapper">
		<div class="view-content view-content--turnstile-height mx-auto">
			<div
				v-if="activeItem == 'password' && !showFullPasswordForm"
				class="mb-6 headline-1">
				{{ $t("menu-items.select_login_method") }}
			</div>
			<div
				v-else
				class="mb-6 headline-1">
				{{ $t(`menu-items.login_with_${activeItem}`) }}
			</div>
			<Alert v-if="getError" />
			<transition
				name="fade"
				mode="out-in">
				<div v-if="activeItem == 'password'">
					<ThirdParty
						v-if="(thirdPartyAllowedMethods && thirdPartyAllowedMethods.length > 0) && (mobileView ? thirdPartyAllowedForMobile : true) && !showFullPasswordForm" />
					<Password
						:showFullPasswordForm="showFullPasswordForm"
						@showFullForm="showFullForm" />
				</div>
				<Mobile v-if="activeItem == 'mobile_id'" />
				<Smart v-if="activeItem == 'smart_id'" />
				<Har v-if="activeItem == 'har_id'" />
				<XId v-if="activeItem == 'x_id'" />
				<Card v-if="activeItem == 'id_card'" />
				<EParaksts v-if="activeItem == 'e_paraksts'" />
			</transition>
			<div v-if="activeItem == '' || activeItem == 'password' && !showFullPasswordForm">
				<div
					class="other-methods">
					<span class="caption-1 caption-1-plus grayscale500--text">{{ $t("menu-items.other_methods") }}</span>
					<span
						v-for="loginMethod in otherLoginMethods"
						:key="loginMethod"
						class="caption-1 caption-1-plus primary-text-link cursor-pointer"
						@click="navigate(loginMethod)">
						{{ $t("menu-items." + loginMethod) }}
					</span>	
				</div>
				<div class="separator mt-4 mb-8" />
				<div
					v-if="!miniView && project == 'eKool' && !mobileCodeSent && !smartCodeSent && !smartLoggedIn && !mobileLoggedIn"	
					class="mt-4 text-center d-block d-sm-flex align-center justify-center">
					<span class="subhead-2 greybase-text mr-2">{{ $t('menu-items.new_to_ekool') }}</span>
					<router-link
						tag="span"
						class="body-3 body-3-plus primary-text-link cursor-pointer"
						:to="'/' + $i18n.locale + '/register'">
						{{ $t('menu-items.create_account') }}
					</router-link>
				</div>
			</div>
			<div
				v-else
				class="d-flex justify-center mt-12">
				<span
					class="body-3 body-3-plus primary-text-link cursor-pointer"
					@click="backToLogin">{{ $t('menu-items.back_to_login') }}</span>
			</div>
			<div
				v-show="showCFTurnstileWidget && activeItem == 'password'"
				class="turnstile-widget mt-4"
				:class="{'d-flex': showCFTurnstileWidget && activeItem == 'password'}" />
		</div>
		<ThirdPartyLinkModal
			v-if="showThirdPartyLinkModal"
			:method="$route.query.type"
			:hash="$route.query.hash" />
		<ThirdPartyRegisterLinkModal
			v-if="showThirdPartyRegisterLinkModal"
			:method="$route.query.type" />
	</div>
</template>

<script>
// @ is an alias to /src

import Password from "@/components/Password.vue";
import Mobile from "@/components/Mobile.vue";
import Smart from "@/components/Smart.vue";
import Har from "@/components/Har.vue";
import Card from "@/components/Card.vue";
import XId from "@/components/XId.vue";
import EParaksts from "@/components/eParaksts.vue";
import ThirdParty from "@/components/ThirdParty.vue";
import ThirdPartyLinkModal from "@/components/ThirdPartyLinkModal.vue";
import ThirdPartyRegisterLinkModal from "@/components/ThirdPartyRegisterLinkModal.vue";

import Alert from "@/components/Alert.vue";
import { mapGetters, mapState } from 'vuex';

import { getEnv } from "../env";
export default {
	components: {
		Password,
		Mobile,
		Smart,
		Har,
		Card,
		Alert,
		XId,
		EParaksts,
		ThirdParty,
		ThirdPartyLinkModal,
		ThirdPartyRegisterLinkModal,
	},
	data () {
		return {
			activeItem: "password",
			project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
			primaryLoginMethods: getEnv('VUE_APP_FRONTEND_PRIMARY_LOGIN_METHODS').split(", "),
			secondaryLoginMethods: getEnv('VUE_APP_FRONTEND_SECONDARY_LOGIN_METHODS').split(", "),
			showCFTurnstileWidget: false,
			showCFTurnstileWidgetTimeout: null,
			thirdPartyAllowedMethods: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS') ? getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(", ") : null,
			showThirdPartyLinkModal: false,
			thirdPartyAllowedForMobile: getEnv('VUE_APP_THIRD_PARTY_ALLOWED_FOR_MOBILE'),
			showFullPasswordForm: false,
			showThirdPartyRegisterLinkModal: false,
		};
	},
	computed: {
		...mapGetters([
			'getError',
			'mobileCodeSent',
			'smartCodeSent',
			'smartLoggedIn',
			'mobileLoggedIn',
			'CFTurnstileWidgetId',
			'CFTurnstileScriptLoaded',
		]),
		...mapState ([
			'miniView',
			'mobileView',
		]),
		otherLoginMethods (){
			let allMethods = this.primaryLoginMethods.concat(this.secondaryLoginMethods);
			return allMethods.filter(e => e !== this.activeItem);
		},
	},
	watch: {
		activeItem (val) {
			if(val !== 'password'){
				if(this.showCFTurnstileWidgetTimeout){
					clearTimeout(this.showCFTurnstileWidgetTimeout);
					this.showCFTurnstileWidget = false;
				}
			}
			else if(val == 'password' && this.CFTurnstileScriptLoaded){
				this.initCFTurnstile();
			}
		},
		CFTurnstileScriptLoaded (val) {
			if(val && this.activeItem == 'password'){
				this.initCFTurnstile();
			}
		},
	},
	mounted () {
		if(this.$route.name == "login-mini" || this.$route.name == 'thirdParty'){
			this.$store.commit("setMiniView",true);
			this.$store.commit("setMiniViewFunction", this.postLoginMessage);
		}
		else if(this.$route.name == "mobile"){
			this.$store.commit("setMobileView",true);
		}

		if(this.$route.params.error){
			if(this.$i18n.te(this.$route.params.error)){
				this.$store.commit("changeError",
					this.$route.params.error);
				this.$store.commit("showError",true);
			}
			else{
				this.$store.commit("changeError",
					("repeating_errors." + this.$route.params.error));
				this.$store.commit("showError",true);
			}

		}
		else if(this.$route.query.error){
			if(this.$i18n.te(this.$route.query.error)){
				this.$store.commit("changeError",
					this.$route.query.error);
				this.$store.commit("showError",true);
			}
			else{
				this.$store.commit("changeError",
					("repeating_errors." + this.$route.query.error));
				this.$store.commit("showError",true);
			}
		}

		if(this.$route.name == 'mobile'){
			//remove id-card login method in mobile view
			this.primaryLoginMethods = this.primaryLoginMethods.filter(e => e !== 'id_card')
		}	
		else{
			//if not mobile view, take login methods from env
			this.primaryLoginMethods = getEnv('VUE_APP_FRONTEND_PRIMARY_LOGIN_METHODS').split(", ")
		}
		if(this.CFTurnstileScriptLoaded && this.activeItem == 'password'){
			this.initCFTurnstile();
		}
		//message route query param
		if(this.$route.query.message){
			if(this.$route.query.type){
				this.$route.query.message == 'register_account' ? this.showThirdPartyRegisterLinkModal = true : this.showThirdPartyLinkModal = true;
			}
			else{
				this.$eToast(this.$i18n.t(`messages.${this.$route.query.message}`));
			}
		}
	},
	beforeDestroy () {
		/* eslint-disable no-undef */
		if(this.showCFTurnstileWidgetTimeout && this.activeItem == 'password'){
			clearTimeout(this.showCFTurnstileWidgetTimeout);
			this.showCFTurnstileWidget = false;
		}
	},
	methods: {
		navigate (item) {
			this.activeItem = item;
			this.$store.commit("showError", false);
		},
		initCFTurnstile (){
			this.$store.commit("setDisableButton",true);
			/* eslint-disable no-undef */
			let widgetId = turnstile.render('.turnstile-widget', {
				sitekey: getEnv('VUE_APP_FRONTEND_CF_TURNSTILE_SITE_KEY'),
				theme: this.$vuetify.theme.dark ? 'dark' : 'light',
				callback: (token) => {
					this.$store.commit("setCFTurnstileToken",token);
					this.$store.commit("setDisableButton",false);
					this.showCFTurnstileWidget = false;
				},
				'error-callback': () => {
					if(this.mobileView){
						this.$store.commit("setDisableButton",false);
					}
					this.showCFTurnstileWidgetTimeout = setTimeout(() => this.showCFTurnstileWidget = true,5000);
				},
			});
			this.$store.commit("setCFTurnstileWidgetId",widgetId);
			//reset CF turnstile every 295 seconds
			setInterval(() => {
				turnstile.reset(this.CFTurnstileWidgetId);
			}, 295000)
		},
		showFullForm (val){
			this.showFullPasswordForm = val;
		},
		backToLogin (){
			this.activeItem = 'password';
			this.showFullPasswordForm = false;
		},
	},

};
</script>