<template>
	<div class="mb-6">
		<e-hint
			:title="$t(getErrorContent)"
			variant="danger"
			dismissible
			@input="closeError()" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data () {
		return {};
	},
	computed: {
		...mapGetters([
			'getErrorContent',
		]),
	},
	methods: {
		closeError () {
			this.$store.commit("showError", false);
		},
	},
};
</script>
