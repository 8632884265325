<template>
	<div class="terms-view">
		<e-dialog
			v-model="dialog"
			persistent
			max-width="484"
			hide-default-header>
			<template #content>
				<div class="d-flex flex-column align-center">
					<div class="w-100 pa-8">
						<div class="d-flex mb-8 justify-center align-center">
							<div class="logo-container mr-2">
								<img
									src="../assets/ekool-logo.svg"
									alt="eKool logo">
								<img
									class="logo-container__protected-logo"
									src="../assets/arrows-icon.svg"
									alt="arrows icon">
							</div>
						</div>
						<div class="d-flex flex-column align-center">
							<span
								class="headline-3 mb-1 text-center">{{ $t('third_party_login.register_title', {method: methodNameCapitalize}) }}</span>
							<span
								class="body-2 text-center"
								:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'"> {{ $t('third_party_login.register_description', {method: methodNameCapitalize}) }}</span>
						</div>
					</div>
					<div class="d-flex w-100 g-3 pa-4 justify-center">
						<e-button
							:block="false"
							variant="tertiary"
							@click="cancel">
							{{ $t('terms.cancel') }}
						</e-button>
						<e-button
							:block="false"
							variant="primary"
							@click="getRegisteringUserData">
							{{ $t('third_party_login.register_account') }}
						</e-button>
					</div>
				</div>
			</template>
		</e-dialog>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		method: {
			type: String,
			required: true,
		},
	},
	data (){
		return{
			dialog: true,
		}
	},
	computed:{
		...mapGetters([
		]),
		methodNameCapitalize (){
			return this.method.charAt(0).toUpperCase() + this.method.slice(1);
		},
	},
	mounted (){
		this.dialog = true;
	},
	methods: {
		close () {
			this.dialog = false;
		},
		cancel () {
			this.$eToast(this.$i18n.t(`messages.check_login_details`));
			this.close();
		},
		getRegisteringUserData () {
			this.$router.push({
				name: "validation",
				query: {
					type: `${this.method}-register`,
				},
			});
			this.close();
		},
	},
}
</script>