<template>
	<div class="terms-view">
		<e-dialog
			v-model="dialog"
			persistent
			max-width="484"
			hide-default-header>
			<template #content>
				<div class="d-flex flex-column align-center">
					<div class="w-100 pa-8">
						<div class="d-flex mb-8 justify-center align-center">
							<div class="logo-container mr-2">
								<img
									src="../assets/ekool-logo.svg"
									alt="eKool logo">
								<img
									class="logo-container__protected-logo"
									src="../assets/protected-logo.svg"
									alt="shield logo">
							</div>
							<div class="arrow-icons">
								<v-icon
									size="20"
									class="arrow-icons__arrow-left">
									{{ $vuetify.theme.dark ? 'icon-arrow-left grayscale600--text' : 'icon-arrow-left grayscale400--text' }}
								</v-icon>
								<v-icon
									size="20"
									class="arrow-icons__arrow-right">
									{{ $vuetify.theme.dark ? 'icon-arrow-right grayscale600--text' : 'icon-arrow-right grayscale400--text' }}
								</v-icon>
							</div>
							<div class="logo-container">
								<img
									:src="require(`../assets/${method}-logo.svg`)"
									alt="logo"
									height="42px">
							</div>
						</div>
						<div class="d-flex flex-column align-center">
							<span
								class="headline-3 mb-1">{{ $t('third_party_login.connect_title', {method: methodNameCapitalize}) }}</span>
							<span
								class="body-2 text-center"
								:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'"> {{ $t('third_party_login.connect_description', {method: methodNameCapitalize}) }}</span>
						</div>
					</div>
					<div class="d-flex w-100 g-3 pa-4 justify-center">
						<e-button
							:block="false"
							variant="tertiary"
							@click="cancel">
							{{ $t('terms.cancel') }}
						</e-button>
						<e-button
							:block="false"
							variant="primary"
							:href="connectAccountUrl">
							{{ $t('third_party_login.connect_account') }}
						</e-button>
					</div>
				</div>
			</template>
		</e-dialog>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		method: {
			type: String,
			required: true,
		},
		hash: {
			type: String,
			required: true,
		},
	},
	data (){
		return{
			dialog: true,
		}
	},
	computed:{
		...mapGetters([
		]),
		connectAccountUrl (){
			return `link/${this.method}/callback?hash=${this.hash}`;
		},
		methodNameCapitalize (){
			return this.method.charAt(0).toUpperCase() + this.method.slice(1);
		},
	},
	watch: {
	},
	mounted (){
		this.dialog = true;
	},
	beforeDestroy (){
	},
	methods: {
		close () {
			this.dialog = false;
		},
		cancel () {
			this.$eToast(this.$i18n.t(`messages.accounts_were_not_linked`));
			this.close();
		},
	},
}
</script>