<template>
	<div class="terms-container">
		<div v-html="privacyPolicyTerms" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	data (){
		return{
			dialog: true,
			prevShowIlustration: false,
			prevShowFooter: false,
		}
	},
	computed:{
		...mapGetters([
			'privacyPolicyTermsBusy',
			'privacyPolicyTerms',
			'showIlustration',
			'showFooter',
		]),
	},
	mounted (){
		//HTML body height fix for dark mode
		document.body.style.height = 'auto';

		this.prevShowIlustration = this.showIlustration
		this.prevShowFooter = this.showFooter

		this.$store.commit("showIlustration",false); //hide ilustration
		this.$store.commit("showFooter",false); //hide footer
		this.$store.commit("showLanguages",false); //hide Languages
		this.getServiceTerms();
	},
	beforeDestroy (){
		//HTML body height fix for dark mode
		document.body.style.height = '100%';
		this.prevShowIlustration ? this.$store.commit("showIlustration", true) : null //show ilustration
		this.prevShowFooter ? this.$store.commit("showFooter",true) : null //show footer
		this.$store.commit("showLanguages",true); //show Languages
	},
	methods: {
		getServiceTerms () {
			this.$store.dispatch('getPrivacyPolicyTerms').then(
			)
		},
	},
	
}
</script>