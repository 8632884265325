<template>
	<div class="mb-0">
		<div class="text-center py-4 px-10 headline-4">
			{{ $t("x_id.x_id_text") }}
		</div>
		<e-button
			class="mt-4"
			:block="true"
			:href="link"
			variant="primary"
			size="large"
			large>
			{{ $t('password_login.login') }}
		</e-button>
	</div>
</template>

<script>
export default {
	props: {
		register: Boolean,
	},
	data () {
		return {};
	},
	computed: {
		link () {
			if(this.register){
				return "register/x-id";
			}else{
				return "/x-id";
			}
		},
	},
};
</script>
