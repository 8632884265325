<template>
	<div class="view-content__wrapper">
		<div class="view-content">
			<div
				class="d-flex justify-center align-center"
				:class="activeItem !== '' || activeItem == 'trustDevice' ? 'mb-8' : 'mb-6'">
				<div
					v-if="project == 'eKool'"
					class="logo-container"
					:class="{'mr-2': activeItem !== '' && activeItem !== 'trustDevice'}">
					<img
						src="../assets/ekool-logo.svg"
						alt="eKool logo">
					<img
						class="logo-container__protected-logo"
						src="../assets/protected-logo.svg"
						alt="shield logo">
				</div>
				<div
					v-if="activeItem !== '' && activeItem !== 'trustDevice' && project == 'eKool'"
					class="arrow-icons">
					<v-icon
						size="20"
						class="arrow-icons__arrow-left">
						{{ $vuetify.theme.dark ? 'icon-arrow-left grayscale600--text' : 'icon-arrow-left grayscale400--text' }}
					</v-icon>
					<v-icon
						size="20"
						class="arrow-icons__arrow-right">
						{{ $vuetify.theme.dark ? 'icon-arrow-right grayscale600--text' : 'icon-arrow-right grayscale400--text' }}
					</v-icon>
				</div>
				<div
					v-if="activeItem !== '' && activeItem !== 'trustDevice'"
					class="logo-container">
					<img
						v-if="activeItem == 'sms'"
						:src="$vuetify.theme.dark ? require(`../assets/sms-logo-white.svg`) : require(`../assets/sms-logo.svg`)"
						alt="SMS logo">
					<img
						v-if="activeItem == 'email'"
						:src="$vuetify.theme.dark ? require(`../assets/letter-logo-white.svg`) : require(`../assets/letter-logo.svg`)"
						alt="letter logo">
					<img
						v-if="activeItem == 'otp'"
						:src="$vuetify.theme.dark ? require(`../assets/phone-logo-white.svg`) : require(`../assets/phone-logo.svg`)"
						alt="phone logo">
					<img
						v-if="activeItem == 'recovery'"
						:src="$vuetify.theme.dark ? require(`../assets/key-icon-white.svg`) : require(`../assets/key-icon-black.svg`)"
						alt="key icon">
				</div>
			</div>
			<div
				class="headline-1 text-center"
				:class="activeItem == '' ? 'mb-6' : 'mb-1'">
				<span v-if="activeItem == 'otp'">{{ $t("two_factor_options.check_your_authenticatior") }}</span>
				<span v-else-if="activeItem == 'email'">{{ $t("two_factor_options.check_your_inbox") }}</span>
				<span v-else-if="activeItem == 'sms'">{{ $t("two_factor_options.check_your_messages") }}</span>
				<span v-else-if="activeItem == 'trustDevice'">{{ $t("two_factor_options.trust_device_title") }}</span>
				<span v-else-if="activeItem == 'recovery'">{{ $t("two_factor_options.recovery_title") }}</span>
				<span v-else>{{ $t("two_factor_options.select_authentication_method") }}</span>
			</div>
			<transition
				name="fade"
				mode="out-in">
				<div v-if="activeItem == ''">
					<div
						v-for="(method, index) in availableMethods"
						:key="index"
						:class="{'mb-4': index < availableMethods.length}"
						class="two-factor-option"
						@click="selectItem(method.name)">
						<div class="two-factor-option__icon">
							<v-icon
								v-if="method.icon"
								size="20">
								{{ `icon-${method.icon} primary400--text` }}
							</v-icon>
							<img
								v-else
								:src="method.imageSrc">
						</div>
						<div class="two-factor-option__content ml-3">
							<div class="two-factor-option__content__header">
								<span
									class="subhead-2"
									:class="$vuetify.theme.dark ? 'grayscale100--text' : 'grayscale800--text'">
									{{ $t(method.title) }}
								</span>    
								<v-icon size="24">
									icon-chevron-right primary300--text
								</v-icon>
							</div>
							<div>
								<span
									class="body-3"
									:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
									{{ $t(method.description) }}
								</span>
							</div>
						</div>
					</div>
					<div class="d-flex justify-center">
						<e-button
							variant="tertiary"
							@click="cancel">
							{{ $t('terms.cancel') }}
						</e-button>
					</div>
				</div>    
				<TrustDevice
					v-if="activeItem == 'trustDevice'" />
				<TwoFactor
					v-else-if="activeItem !== ''"
					:method="activeItem" />
			</transition>
			<div
				v-if="activeItem !== '' && activeItem !== 'trustDevice'"
				class="d-flex flex-column align-center">
				<div class="separator my-6" />
				<span
					class="mt-4 caption-1 caption-1-plus cursor-pointer"
					:class="$vuetify.theme.dark ? 'primary300--text' : 'primary400--text'"
					@click="selectItem('')">{{ $t('two_factor_options.try_different_method') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

import TwoFactor from "@/components/TwoFactor.vue";
import TrustDevice from "@/components/TrustDevice.vue";
import { mapState, mapGetters } from 'vuex';
import { getEnv } from "../env";
export default {
	components: {
		TwoFactor,
		TrustDevice,
	},
	data () {
		return {
			activeItem: "",
			allowedMethods: [
				{ name: 'otp', title: 'two_factor_options.otp', icon: 'phone', description: 'two_factor_options.otp_description' },
				{ name: 'email', title: 'two_factor_options.email', icon: 'letter', description: 'two_factor_options.email_description' },
				{ name: 'sms', title: 'two_factor_options.sms', icon: 'message-bubbles', description: 'two_factor_options.sms_description' },
				{ name: 'recovery', title: 'two_factor_options.recovery', imageSrc: require(`../assets/key-icon.svg`), description: 'two_factor_options.recovery_description' },
			],
			availableMethods: [],
			timeLeft: 0,
			timeoutTimer: false,
			project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
		};
	},
	computed: {
		...mapState([
			'mobileView',
		]),
		...mapGetters([
			'getError',
			'twoFactorAvailableMethods',
			'twoFactorRequestBusy',
			'twoFactorRequestSuccess',
			'twoFactorRequestError',
			'twoFactorRequestErrors',
			'twoFactorRequestTimeout',
			'rememberRequestRequired',
		]),
	},
	watch:{
		twoFactorAvailableMethods (){
			this.handleAvailableMethods()
		},
		rememberRequestRequired (val){
			if(val){
				this.selectItem('trustDevice')
			}
		},
	},
	mounted () {
		this.handleAvailableMethods()
	},
	methods: {
		selectItem (item){
			const method = item
			if(method !== 'otp' && method !== 'trustDevice' && method !== 'recovery' && method !== ''){
				this.$store.dispatch('twoFactorRequest', { method }).then(
					() => {
						if(this.twoFactorRequestSuccess){
							this.activeItem = item;
						}
						else{
							this.countdown();
							this.twoFactorRequestErrors == 'too_many_requests' ? this.$eToast.error(this.$i18n.t('setup_account.too_many_requests', { x : this.timeLeft })) : this.$eToast.error(this.$i18n.t(`setup_account.${this.twoFactorRequestErrors}`))
						}
					},
				)
			}
			else{
				this.activeItem = item;
			}
			
		},
		handleAvailableMethods (){
			try{
				this.availableMethods = this.allowedMethods.filter(method =>
					this.twoFactorAvailableMethods.some(availableMethod => availableMethod.name === method.name),
				);
				const priorityMethod = this.twoFactorAvailableMethods.find(method => method.priority === true);
				//set activeItem by priority
				this.activeItem = priorityMethod.name
			}
			catch{
				this.$router.push({
					name: "login",
				})
			}
			
		},
		countdown (){
			clearInterval(this.timeoutTimer);
			this.timeLeft = this.twoFactorRequestTimeout
			if(this.timeLeft > 0){
				//this.requestNew = true;
			}
			this.timeoutTimer = setInterval(() => {
				if(this.timeLeft == 0){
					clearInterval(this.timeoutTimer);
					//this.requestNew = false;
				}
				else{
					this.timeLeft -= 1;
				}
			}, 1000);
		},
		cancel (){
			this.$router.push({
				name: "login",
			});
		},
	},
};
</script>
