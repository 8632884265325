import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		thirdPartyBusy: true,
		permissionData: {},
		thirdPartyRequestBusy: false,
	},
	mutations: {
		getThirdPartyData (state){
			state.thirdPartyBusy = true
		},
		getThirdPartyDataSuccess (state, response) {
			state.thirdPartyBusy = false
			state.permissionData = response.data.data
		},
		getThirdPartyDataFail (state) {
			state.thirdPartyBusy = false
		},
		thirdPartyRequest (state) {
			state.thirdPartyRequestBusy = true
		},
		thirdPartyRequestSuccess (state) {
			state.thirdPartyRequestBusy = false
		},
		thirdPartyRequestFail (state) {
			state.thirdPartyRequestBusy = false
		},
	},
	actions: {
		getThirdPartyData: (store) => {
			store.commit('getThirdPartyData')
			return api.getThirdPartyData()
				.then(
					(response) => store.commit('getThirdPartyDataSuccess', response),
					error => store.commit('getThirdPartyDataFail', error))
		},
		acceptThirdPartyRequest: (store) => {
			store.commit('thirdPartyRequest')
			// let permisionState = store.state.permissionData.state
			// let clientId = store.state.permissionData.client_id
			// return api.acceptThirdPartyRequest(permisionState, clientId)
			// 	.then(
			// 		(response) => store.commit('thirdPartyRequestSuccess', response),
			// 		error => store.commit('thirdPartyRequestFail', error))
		},
		denyThirdPartyRequest: (store) => {
			store.commit('thirdPartyRequest')
			// let permisionState = store.state.permissionData.state
			// let clientId = store.state.permissionData.client_id
			// return api.denyThirdPartyRequest(permisionState, clientId)
			// 	.then(
			// 		(response) => store.commit('thirdPartyRequestSuccess', response),
			// 		error => store.commit('thirdPartyRequestFail', error))
		},
	},
	getters: {
		permissionDataEdited: (state) => {
			// let iconTypes = {
			// 	'calendar': 'calendar',
			// 	'permission': 'permission',
			// 	'contacts': 'contacts',
			// 	'stats': 'stats',
			// 	'data': 'data',
			// 	'messages': 'messages',
			// }
			let editedPermissions = state.permissionData ? state.permissionData : []
			editedPermissions.scopes.map(scope => {
				scope.icon = 'icon-hard-drive'
				// scope.icon = iconTypes[scope.type] ? iconTypes[scope.type] : iconTypes.data
				return scope
			})
			return editedPermissions
		},
	},
}