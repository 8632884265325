import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		schoolRegisterBusy: false,
		schoolRegisterError: false,
		schoolRegisterSuccess: false,
		schoolRegisterErrors: undefined,
	},
	mutations: {
		schoolRegister (state){
			state.schoolRegisterBusy = true;
		},
		schoolRegisterSuccess (state,response){
			state.schoolRegisterBusy = false;
			state.schoolRegisterSuccess = response.body.success;
		},
		schoolRegisterFail (state,error){
			state.schoolRegisterBusy = false;
			state.schoolRegisterError = true;
			state.schoolRegisterErrors = error.body.errors;
		},
	},
	actions: {
		schoolRegister: (store, { schoolName,registrationNumber,type,street,city,country,firstName,lastName,email,number }) => {
			store.commit('schoolRegister')
			return api.schoolRegister(schoolName,registrationNumber,type,street,city,country,firstName,lastName,email,number,)
				.then(
					response => {
						store.commit('schoolRegisterSuccess',response)
						return response;
					},
					error => {
						store.commit('schoolRegisterFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		schoolRegisterBusy: state => state.schoolRegisterBusy,
		schoolRegisterError: state => state.schoolRegisterError,
		schoolRegisterSuccess: state => state.schoolRegisterSuccess,
		schoolRegisterErrors: state => state.schoolRegisterErrors,
	},
}