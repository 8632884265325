<template>
	<div class="view-content d-block d-md-flex align-center justify-center mx-auto">
		<e-circular-loading
			value
			:fullPage="false"
			:size="42" />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data (){
		return{
			type: "",
			user: {},
		}
	},
	computed: {
		...mapGetters([
			'harValidationBusy',
			'harValidationErrors',
			'harValid',
			'harUser',
			'harMultipleAccounts',
			'harAccounts',
			'xidValidationBusy',
			'xidValidationErrors',
			'xidValid',
			'getGoogleRegisteringUserBusy',
			'getGoogleRegisteringUserSuccess',
			'getGoogleRegisteringUserErrors',
			'googleRegisteringUser',
			'getMicrosoftRegisteringUserBusy',
			'getMicrosoftRegisteringUserSuccess',
			'getMicrosoftRegisteringUserErrors',
			'microsoftRegisteringUser',
			'getEliisRegisteringUserBusy',
			'getEliisRegisteringUserSuccess',
			'getEliisRegisteringUserErrors',
			'eliisRegisteringUser',
			'getTwoFactorMethodsBusy',
			'getTwoFactorMethodsSuccess',
			'getTwoFactorMethodsErrors',
		]),
	},
	mounted () {
		if(this.$route.query.type == 'har' || this.$route.query.type == 'har-register' || this.$route.query.type == 'xid' || this.$route.query.type == 'google-register' || this.$route.query.type == 'microsoft-register' || this.$route.query.type == 'eliis-register'){
			this.type = this.$route.query.type;
			this.validateUserData();
		}
		else if(this.$route.query.type == 'methods'){
			this.getTwoFactorMethods();
		}
		else{
			this.$router.replace({
				name: "login",
			})
		}
	},
	methods: {
		validateUserData (){
			//get har data and redirect to setup account with disabled fields
			if(this.type == 'har-register'){
				this.$store.dispatch('harValidation').then(
					() => {
						//if error
						if(this.harValid == false && this.harValidationErrors){
							this.$router.replace({
								name: "login",
								params: {
									error: 'repeating_errors.' + this.harValidationErrors,
								},
							});
						}
						//if success
						else if(this.harValid){
							this.user = this.harUser
							this.$router.push({
								name: "setupAccount",
								params: {
									user: this.user,
								},
							});
							
						}
						
					},	
				)
			}
			//har multiple accounts
			else if(this.type == 'har'){
				this.$store.dispatch('harChooseUser').then(
					() => {
						//if success
						if(this.harMultipleAccounts){
							this.$router.push({
								name: "chooseUser",
								params: {
									users: this.harAccounts,
								},
							}).catch(() => {});
						}
						//if error
						else{
							this.$router.replace({
								name: "login",
								params: {
									error: 'Error with harID',
								},
							});
						}
					},	
				)
			}
			//get xid data and redirect to setup account with disabled fields
			else if(this.type == 'xid'){
				this.$store.dispatch('xidValidation').then(
					() => {
						//if error
						if(!this.harValid){
							
							this.$router.replace({
								name: "login",
								params: {
									error: 'Error with X-ID',
								},
							});
						}
						//if success
						else if(this.harValid){
							this.$router.push({
								name: "setupAccount",
								params: {
									user: this.user,
								},
							});
						}
						
					},	
				)
			}
			//get google user data and redirect to setup account with prefilled fields
			else if(this.type == 'google-register'){
				this.$store.dispatch('getGoogleRegisteringUser').then(
					() => {
						//if error
						if(!this.getGoogleRegisteringUserSuccess){
							this.$router.replace({
								name: "login",
								params: {
									error: 'Error with Google',
								},
							});
						}
						//if success
						else if(this.getGoogleRegisteringUserSuccess){
							this.user = this.googleRegisteringUser
							this.$router.push({
								name: "setupAccount",
								params: {
									user: this.user,
								},
							});
						}
					},	
				)
			}
			//get microsoft user data and redirect to setup account with prefilled fields
			else if(this.type == 'microsoft-register'){
				this.$store.dispatch('getMicrosoftRegisteringUser').then(
					() => {
						//if error
						if(!this.getMicrosoftRegisteringUserSuccess){
							this.$router.replace({
								name: "login",
								params: {
									error: 'Error with Microsoft',
								},
							});
						}
						//if success
						else if(this.getMicrosoftRegisteringUserSuccess){
							this.user = this.microsoftRegisteringUser
							this.$router.push({
								name: "setupAccount",
								params: {
									user: this.user,
								},
							});
						}
					},	
				)
			}
			//get eliis user data and redirect to setup account with prefilled fields
			else if(this.type == 'eliis-register'){
				this.$store.dispatch('getEliisRegisteringUser').then(
					() => {
						//if error
						if(!this.getEliisRegisteringUserSuccess){
							this.$router.replace({
								name: "login",
								params: {
									error: 'Error with ELIIS',
								},
							});
						}
						//if success
						else if(this.getEliisRegisteringUserSuccess){
							this.user = this.eliisRegisteringUser
							this.$router.push({
								name: "setupAccount",
								params: {
									user: this.user,
								},
							});
						}
					},	
				)
			}
		},
		getTwoFactorMethods (){
			this.$store.dispatch('getTwoFactorMethods').then(
				() => {
					if(this.getTwoFactorMethodsSuccess){
						this.$router.push({
							name: "twoFactorOptions",
						});
					}
					else{
						this.$router.replace({
							name: "login",
							params: {
								error: this.getTwoFactorMethodsErrors,
							},
						});
					}
				},	
			)
		},
	},
}
</script>