import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		getGoogleRegisteringUserBusy: false,
		getGoogleRegisteringUserSuccess: false,
		getGoogleRegisteringUserErrors: undefined,
		googleRegisteringUser: undefined,
		getMicrosoftRegisteringUserBusy: false,
		getMicrosoftRegisteringUserSuccess: false,
		getMicrosoftRegisteringUserErrors: undefined,
		microsoftRegisteringUser: undefined,
		getEliisRegisteringUserBusy: false,
		getEliisRegisteringUserSuccess: false,
		getEliisRegisteringUserErrors: undefined,
		eliisRegisteringUser: undefined,
		passkeysLoginOptionsBusy: false,
		passkeysLoginOptionsSuccess: false,
		passkeysLoginOptionsResponse: undefined,
	},
	mutations: {
		getGoogleRegisteringUser (state){
			state.getGoogleRegisteringUserBusy = true;
		},
		getGoogleRegisteringUserSuccess (state,response){
			state.getGoogleRegisteringUserBusy = false;
			state.getGoogleRegisteringUserSuccess = response.body.success;
			if (response.body.success && response.body.data) {
				state.googleRegisteringUser = response.body.data;
			}
		},
		getGoogleRegisteringUserFail (state,error){
			state.getGoogleRegisteringUserBusy = false;
			if(error.body.errors){
				state.getGoogleRegisteringUserErrors = error.body.errors;
			}
		},
		getMicrosoftRegisteringUser (state){
			state.getMicrosoftRegisteringUserBusy = true;
		},
		getMicrosoftRegisteringUserSuccess (state,response){
			state.getMicrosoftRegisteringUserBusy = false;
			state.getMicrosoftRegisteringUserSuccess = response.body.success;
			if (response.body.success && response.body.data) {
				state.microsoftRegisteringUser = response.body.data;
			}
		},
		getMicrosoftRegisteringUserFail (state,error){
			state.getMicrosoftRegisteringUserBusy = false;
			if(error.body.errors){
				state.getMicrosoftRegisteringUserErrors = error.body.errors;
			}
		},
		getEliisRegisteringUser (state){
			state.getEliisRegisteringUserBusy = true;
		},
		getEliisRegisteringUserSuccess (state,response){
			state.getEliisRegisteringUserBusy = false;
			state.getEliisRegisteringUserSuccess = response.body.success;
			if (response.body.success && response.body.data) {
				state.eliisRegisteringUser = response.body.data;
			}
		},
		getEliisRegisteringUserFail (state,error){
			state.getEliisRegisteringUserBusy = false;
			if(error.body.errors){
				state.getEliisRegisteringUserErrors = error.body.errors;
			}
		},
		getPasskeysLoginOptions (state){
			state.passkeysLoginOptionsBusy = true;
			state.passkeysLoginOptionsSuccess = false;
			state.passkeysLoginOptionsResponse = undefined;
		},
		getPasskeysLoginOptionsSuccess (state,response){
			state.passkeysLoginOptionsBusy = false;
			if(response.body){
				state.passkeysLoginOptionsSuccess = true;
				state.passkeysLoginOptionsResponse = response.body;
			}
		},
		getPasskeysLoginOptionsFail (state){
			state.passkeysLoginOptionsBusy = false;
			state.passkeysLoginOptionsSuccess = false;
		},
	},
	actions: {
		getGoogleRegisteringUser: (store) => {
			store.commit('getGoogleRegisteringUser')
			return api.getGoogleRegisteringUser()
				.then(
					response => {
						store.commit('getGoogleRegisteringUserSuccess',response)
						return response;
					},
					error => {
						store.commit('getGoogleRegisteringUserFail', error)
						return error;
					},
				)
				
		},
		getMicrosoftRegisteringUser: (store) => {
			store.commit('getMicrosoftRegisteringUser')
			return api.getMicrosoftRegisteringUser()
				.then(
					response => {
						store.commit('getMicrosoftRegisteringUserSuccess',response)
						return response;
					},
					error => {
						store.commit('getMicrosoftRegisteringUserFail', error)
						return error;
					},
				)
				
		},
		getEliisRegisteringUser: (store) => {
			store.commit('getEliisRegisteringUser')
			return api.getEliisRegisteringUser()
				.then(
					response => {
						store.commit('getEliisRegisteringUserSuccess', response)
						return response;
					},
					error => {
						store.commit('getEliisRegisteringUserFail', error)
						return error;
					},
				)
				
		},
		getPasskeysLoginOptions: (store) => {
			store.commit('getPasskeysLoginOptions')
			return api.getPasskeysLoginOptions()
				.then(
					response => {
						return response;
					},
					error => {
						return error;
					},
				)
				
		},
		getPasskeysVerification: (store, { passkeysAuthorizationResp } ) => {
			return api.getPasskeysVerification(passkeysAuthorizationResp)
				.then(
					response => {
						return response;
					},
					error => {
						return error;
					},
				)
				
		},
		getPasskeysRegisterOptions: (store, { userName } ) => {
			return api.getPasskeysRegisterOptions(userName)
				.then(
					response => {
						//store.commit('getPasskeysLoginOptionsSuccess', response)
						return response;
					},
					error => {
						//store.commit('getPasskeysLoginOptionsFail', error)
						return error;
					},
				)
				
		},
		getPasskeysRegisterVerification: (store, { passkeysAuthorizationResp } ) => {
			return api.getPasskeysRegisterVerification(passkeysAuthorizationResp)
				.then(
					response => {
						return response;
					},
					error => {
						return error;
					},
				)
				
		},
	},
	getters: {
		getGoogleRegisteringUserBusy: state => state.getGoogleRegisteringUserBusy,
		getGoogleRegisteringUserSuccess: state => state.getGoogleRegisteringUserSuccess,
		getGoogleRegisteringUserErrors: state => state.getGoogleRegisteringUserErrors,
		googleRegisteringUser: state => state.googleRegisteringUser,
		getMicrosoftRegisteringUserBusy: state => state.getMicrosoftRegisteringUserBusy,
		getMicrosoftRegisteringUserSuccess: state => state.getMicrosoftRegisteringUserSuccess,
		getMicrosoftRegisteringUserErrors: state => state.getMicrosoftRegisteringUserErrors,
		microsoftRegisteringUser: state => state.microsoftRegisteringUser,
		getEliisRegisteringUserBusy: state => state.getEliisRegisteringUserBusy,
		getEliisRegisteringUserSuccess: state => state.getEliisRegisteringUserSuccess,
		getEliisRegisteringUserErrors: state => state.getEliisRegisteringUserErrors,
		eliisRegisteringUser: state => state.eliisRegisteringUser,
	},
}