<template>
	<div class="terms-view">
		<e-dialog
			v-model="dialog"
			persistent
			max-width="484"
			hide-default-header>
			<template #content>
				<div class="pa-8 d-flex flex-column align-center">
					<div class="d-flex mb-8 justify-center align-center">
						<div class="logo-container mr-2">
							<img
								src="../assets/ekool-logo.svg"
								alt="eKool logo">
							<img
								class="logo-container__protected-logo"
								src="../assets/protected-logo.svg"
								alt="shield logo">
						</div>
						<div class="arrow-icons">
							<v-icon
								size="20"
								class="arrow-icons__arrow-left">
								{{ $vuetify.theme.dark ? 'icon-arrow-left grayscale600--text' : 'icon-arrow-left grayscale400--text' }}
							</v-icon>
							<v-icon
								size="20"
								class="arrow-icons__arrow-right">
								{{ $vuetify.theme.dark ? 'icon-arrow-right grayscale600--text' : 'icon-arrow-left grayscale400--text' }}
							</v-icon>
						</div>
						<div class="logo-container">
							<img
								:src="$vuetify.theme.dark ? require(`../assets/sms-logo-white.svg`) : require(`../assets/sms-logo.svg`)"
								alt="SMS logo">
						</div>
					</div>
					<div class="d-flex flex-column align-center mb-8">
						<span
							class="headline-3 mb-1">{{ $t('setup_account.code_title') }}</span>
						<i18n
							path="setup_account.code_description"
							tag="span"
							class="body-2 text-center"
							:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
							<template #phone>
								<span :class="$vuetify.theme.dark ? 'grayscale200--text' : 'grayscale800--text'">{{ phoneNumber }}</span>
							</template>
							<template #x>
								{{ 30 }}
							</template>
						</i18n>	
					</div>
					<div
						class="d-flex flex-column align-center mb-8"
						style="width: 100%;">
						<div
							id="otp-container"
							class="d-flex mb-3">
							<e-text-field
								v-for="(otpInput, index) in otpInputs"
								:key="index"
								ref="otpInput"
								v-model="otpInputs[index]"
								class="otp-input"
								:class="'otp-input--' + index"
								large=""
								maxlength="6"
								:inputmode="getInputMode()"
								autocomplete="one-time-code"
								@input.native="onInput(index, $event.target.value, $event)"
								@keydown.native="onKeyDown(index, $event)"
								@paste.native="onPaste(index, $event)" />
						</div>
						<div
							v-if="requestNew"
							class="d-flex justify-content-center">
							<span class="caption-1 grayscale400--text">{{ $t('setup_account.request_new_code', {x: timeLeft}) }}</span>
						</div>
						<div
							v-else
							class="d-flex justify-content-center">
							<span class="caption-1 grayscale400--text mr-1">{{ $t('setup_account.didnt_get_code') }}</span>
							<span
								class="caption-1 primary400--text"
								style="cursor: pointer;"
								@click="resendCode()">{{ $t('setup_account.resend_code') }}</span>
						</div>
					</div>
					<e-button
						:block="$vuetify.breakpoint.mobile"
						variant="tertiary"
						@click="close">
						{{ $t('terms.cancel') }}
					</e-button>
				</div>
			</template>
		</e-dialog>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		countryCode: String,
		phoneNumber: String,
	},
	data (){
		return{
			dialog: true,
			otpInputs: Array(6).fill(''), // Adjust the length as needed
			requestNew: false,
			timeLeft: 0,
			timeoutTimer: false,
		}
	},
	computed:{
		...mapGetters([
			'showPhoneNumberModal',
			'registerPhoneVerifyVerifyBusy',
			'registerPhoneVerifyVerifySuccess',
			'registerPhoneVerifyVerifyError',
			'registerPhoneVerifyVerifyErrors',
			'registerPhoneVerifyRequestTimeout',
			'registerPhoneVerifyCodeBusy',
			'registerPhoneVerifyCodeSuccess',
			'registerPhoneVerifyCodeError',
			'registerPhoneVerifyCodeErrors',
			'CFTurnstileToken',
			'CFTurnstileWidgetId',
		]),
		codeError (){
			return this.registerPhoneVerifyCodeError
		},
	},
	watch: {
		codeError (val){
			if(val){
				this.$eToast.error(this.$i18n.t(`setup_account.${this.registerPhoneVerifyCodeErrors}`))
			}
		},
		registerPhoneVerifyRequestTimeout (){
			this.countdown()
		},
	},
	mounted (){
		this.dialog = true;
		this.requestNew = false;
		this.countdown();
	},
	beforeDestroy (){
	},
	methods: {
		close () {
			this.dialog = false;
			this.$store.commit('setPhoneNumberModal', false)
		},
		onInput (index, value, event) {
			// Allow only digits (0-9)
			const digit = value.trim();
			// Workaround for mobile chrome not firing paste event on sms auto-populate
			if(digit.length > 1){
				event.clipboardData = {
					getData: () => digit,
				}
				this.onPaste(0,event)
			}
			if (/^\d$/.test(digit)) {
				this.$set(this.otpInputs, index, digit);
				if (index < this.otpInputs.length - 1 && digit) {
					this.focusNextInput(index);
				}
			} else {
				// Clear the input if it's not a digit
				event.preventDefault()
				this.$set(this.otpInputs, index, '');
			}
			// Check if all fields are filled
			if (this.isOTPComplete()) {
				this.registerPhoneVerifyCode();
			}
		},
		onKeyDown (index, event) {
			const key = event.keyCode || event.which;

			if (key === 8 && index > 0 && !this.otpInputs[index]) {
				// Handle backspace when the input is empty
				event.preventDefault();
				this.focusPreviousInput(index);
			} else if (key === 8) {
				// Clear the current input on backspace
				event.preventDefault();
				this.$set(this.otpInputs, index, '');
			} else if (key === 37 && index > 0) {
				// Move left
				event.preventDefault();
				this.focusPreviousInput(index);
			} else if (key === 39 && index < this.otpInputs.length - 1) {
				// Move right
				event.preventDefault();
				this.focusNextInput(index);
			} else if (key === 13 || (key === 9 && index === this.otpInputs.length - 1)) {
				// Enter or Tab key (on last input)
				event.preventDefault();
				this.registerPhoneVerifyCode();
			}
		},
		onPaste (index, event) {
			event.preventDefault();
			const clipboardData = event.clipboardData || window.clipboardData;
			let pastedData = clipboardData.getData('Text');
			const startIndex = Math.min(index, this.otpInputs.length - 1);

			for (let i = startIndex; i < this.otpInputs.length; i++) {
				if (pastedData.length > 0) {
					const char = pastedData.charAt(0);
					if (/^\d$/.test(char)) {
						this.$set(this.otpInputs, i, char);
						pastedData = pastedData.substring(1);
					}
				}
			}

			// Check if all fields are filled
			if (this.isOTPComplete()) {
				this.registerPhoneVerifyCode();
			}
		},
		focusNextInput (index) {
			this.$refs['otpInput'][index + 1].focus();
		},
		focusPreviousInput (index) {
			this.$refs['otpInput'][index - 1].focus();
		},
		isOTPComplete () {
			return this.otpInputs.every(input => input !== '');
		},
		registerPhoneVerifyCode (){
			let countryCode = this.countryCode
			let phoneNumber = this.phoneNumber
			let code = this.otpInputs.join('')
			this.$store.dispatch('registerPhoneVerifyCode', { countryCode, phoneNumber, code }).then(
				() => {
					if(this.registerPhoneVerifyCodeSuccess){
						this.close()
					}
				},
			)
		},
		registerPhoneVerifyRequest (){
			let countryCode = this.countryCode
			let phoneNumber = this.phoneNumber
			let token = this.CFTurnstileToken
			this.$store.dispatch('registerPhoneVerifyRequest', { countryCode, phoneNumber, token }).then(
				() => {
					/* eslint-disable no-undef */
					turnstile.reset(this.CFTurnstileWidgetId)
					if(this.registerPhoneVerifyRequestSuccess){
						this.showModal = true
					}
				},
			)
		},
		resendCode (){
			this.registerPhoneVerifyRequest()
		},
		countdown (){
			clearInterval(this.timeoutTimer);
			this.timeLeft = this.registerPhoneVerifyRequestTimeout
			if(this.timeLeft > 0){
				this.requestNew = true;
			}
			this.timeoutTimer = setInterval(() => {
				if(this.timeLeft == 0){
					clearInterval(this.timeoutTimer);
					this.requestNew = false;
				}
				else{
					this.timeLeft -= 1;
				}
			}, 1000);
		},
		getInputMode () {
			// Set inputmode to "numeric" to provide a numerical keyboard on mobile devices
			return 'numeric';
		},
	},
	
}
</script>