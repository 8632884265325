import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		newPasswordBusy: false,
		newPasswordError: false,
		newPasswordSuccess: false,
		newPasswordErrors: undefined,
		newPasswordValidationBusy: false,
		newPasswordValidToken: false,
	},
	mutations: {
		//token validation to be added..
        
		//password reset
		setNewPassword (state){
			state.newPasswordBusy = true;
		},
		setNewPasswordSuccess (state,response){
			state.newPasswordBusy = false;
			if (response.body.success) {
				state.newPasswordSuccess = true;
			}
		},
		setNewPasswordFail (state,error){
			state.newPasswordBusy = false;
			if(error.body.errors){
				state.newPasswordErrors = error.body.errors;
				state.newPasswordError = true;
			}
			else{
				state.newPasswordError = true;
			}
		},
		validateToken (state){
			state.newPasswordValidationBusy = true;
		},
		validateTokenSuccess (state,response){
			state.newPasswordValidationBusy = false;
			if(response.body.success){
				state.newPasswordValidToken = true;
			}
			else{
				state.newPasswordValidToken = false;
			}
		},
		validateTokenFail (state,error){
			state.newPasswordValidationBusy = false;
			state.newPasswordValidToken = false;
			state.newPasswordErrors = error.body.errors;
			state.newPasswordError = true;
		},
		
	},
	actions: {
		setNewPassword: (store, { token,username,password,passwordConfirmation }) => {
			store.commit('setNewPassword')
			return api.resetPassword(token,username,password,passwordConfirmation)
				.then(
					response => {
						store.commit('setNewPasswordSuccess',response)
						return response;
					},
					error => {
						store.commit('setNewPasswordFail', error)
						return error;
					}
				)
		},
		validateToken: (store, { token, type }) => {
			store.commit('validateToken')
			return api.validateToken(token, type)
				.then(
					response => {
						store.commit('validateTokenSuccess',response)
						return response;
					},
					error => {
						store.commit('validateTokenFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		newPasswordBusy: state => state.newPasswordBusy,
		newPasswordError: state => state.newPasswordError,
		newPasswordSuccess: state => state.newPasswordSuccess,
		newPasswordErrors: state => state.newPasswordErrors,
		newPasswordValidToken: state => state.newPasswordValidToken,
		newPasswordValidationBusy: state => state.newPasswordValidationBusy,
	},
}