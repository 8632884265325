import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		adminChangePasswordBusy: false,
		adminChangePasswordError: false,
		adminChangePasswordSuccess: false,
		adminChangePasswordErrors: undefined,
	},
	mutations: {
		//token validation to be added..
        
		//password reset
		adminChangePassword (state){
			state.adminChangePasswordBusy = true;
		},
		adminChangePasswordSuccess (state,response){
			state.adminChangePasswordBusy = false;
			if (response.body.success) {
				state.adminChangePasswordSuccess = true;
			}
		},
		adminChangePasswordFail (state,error){
			state.adminChangePasswordBusy = false;
			if(error.body.errors){
				state.adminChangePasswordErrors = error.body.errors;
				state.adminChangePasswordError = true;
			}
			else{
				state.adminChangePasswordError = true;
			}
		},
		
	},
	actions: {
		adminChangePassword: (store, { token,username,password,passwordConfirmation }) => {
			store.commit('adminChangePassword')
			return api.adminChangePassword(token,username,password,passwordConfirmation)
				.then(
					response => {
						store.commit('adminChangePasswordSuccess',response)
						return response;
					},
					error => {
						store.commit('adminChangePasswordFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		adminChangePasswordBusy: state => state.adminChangePasswordBusy,
		adminChangePasswordError: state => state.adminChangePasswordError,
		adminChangePasswordSuccess: state => state.adminChangePasswordSuccess,
		adminChangePasswordErrors: state => state.adminChangePasswordErrors,
	},
}