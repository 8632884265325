import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		harValidationBusy: false,
		harValidationErrors: undefined,
		harValid: false,
		harUser: undefined,
		harMultipleAccounts: false,
		harAccounts: undefined,
	},
	mutations: {
		//token validation to be added..
        
		//password reset
		harValidation (state){
			state.harValidationBusy = true;
		},
		harValidationSuccess (state,response){
			state.harValidationBusy = false;
			if (response.body.success && response.body.user) {
				state.harUser = response.body.user;
				state.harValid = true;
			}else{
				state.harValid = false;
			}
		},
		harValidationFail (state,error){
			state.harValidationBusy = false;
			state.harValid = false;
			if(error.body.errors){
				state.harValidationErrors = error.body.errors;
			}
		},
		harChooseUser (state){
			state.harValidationBusy = true;
		},
		harChooseUserSuccess (state,response){
			state.harValidationBusy = false;
			state.harMultipleAccounts = response.body.multiple_accounts;
			state.harAccounts = response.body.accounts;
		},
		harChooseUserFail (state,error){
			state.harValidationBusy = false;
			state.harValid = false;
			if(error.body.errors){
				state.harValidationErrors = error.body.errors;
			}
		},
		
	},
	actions: {
		harValidation: (store) => {
			store.commit('harValidation')
			return api.getHarData()
				.then(
					response => {
						store.commit('harValidationSuccess',response)
						return response;
					},
					error => {
						store.commit('harValidationFail', error)
						return error;
					}
				)
				
		},
		harChooseUser: (store) => {
			store.commit('harChooseUser')
			return api.chooseUser()
				.then(
					response => {
						store.commit('harChooseUserSuccess',response)
						return response;
					},
					error => {
						store.commit('harChooseUserFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		harValidationBusy: state => state.harValidationBusy,
		harValidationErrors: state => state.harValidationErrors,
		harValid: state => state.harValid,
		harUser: state => state.harUser,
		harMultipleAccounts: state => state.harMultipleAccounts,
		harAccounts: state => state.harAccounts,
	},
}