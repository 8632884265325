import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'


Vue.use(Vuex)

export default{
	state: {
		serviceTermsBusy: false,
		serviceTerms: "",
		cookiesPrivacyPolicyTermsBusy: false,
		cookiesPrivacyPolicyTerms: "",
		privacyPolicyTermsBusy: false,
		privacyPolicyTerms: "",
		downloadServiceTermsBusy: false,
		downloadPrivacyPolicyBusy: false,
		acceptTermsBusy: false,
		acceptTermsError: "",
		acceptTermsSuccess: false,
		acceptTermsUrl: "",
		serviceTermsReadBusy: false,
		serviceTermsRead: "",
	},
	mutations: {
		getServiceTerms (state){
			state.serviceTermsBusy = true;
		},
		getServiceTermsSuccess (state,response){
			state.serviceTermsBusy = false;
			state.serviceTerms = response.data;
		},
		getServiceTermsFail (state){
			state.serviceTermsBusy = false;
		},
		getPrivacyPolicyTerms (state){
			state.privacyPolicyTermsBusy = true;
		},
		getPrivacyPolicyTermsSuccess (state,response){
			state.privacyPolicyTermsBusy = false;
			state.privacyPolicyTerms = response.data;
		},
		getPrivacyPolicyTermsFail (state){
			state.privacyPolicyTermsBusy = false;
		},
		getCookiesPrivacyPolicyTerms (state){
			state.cookiesPrivacyPolicyTermsBusy = true;
		},
		getCookiesPrivacyPolicyTermsSuccess (state,response){
			state.cookiesPrivacyPolicyTermsBusy = false;
			state.cookiesPrivacyPolicyTerms = response.data;
		},
		getCookiesPrivacyPolicyTermsFail (state){
			state.cookiesPrivacyPolicyTermsBusy = false;
		},
		downloadServiceTerms (state){
			state.downloadServiceTermsBusy = true;
		},
		downloadServiceTermsSuccess (state){
			state.downloadServiceTermsBusy = false;
		},
		downloadServiceTermsFail (state){
			state.downloadServiceTermsBusy = false;
		},
		downloadPrivacyPolicyTerms (state){
			state.downloadPrivacyPolicyTermsBusy = true;
		},
		downloadPrivacyPolicyTermsSuccess (state){
			state.downloadPrivacyPolicyTermsBusy = false;
		},
		downloadPrivacyPolicyTermsFail (state){
			state.downloadPrivacyPolicyTermsBusy = false;
		},
		acceptTerms (state) {
			state.acceptTermsBusy = true
		},
		acceptTermsSuccess (state, response) {
			state.acceptTermsBusy = true
			state.acceptTermsSuccess = response.data.success
			state.acceptTermsUrl = response.data.url
		},
		acceptTermsFail (state, error) {
			state.acceptTermsBusy = true
			state.acceptTermsError = error.body.errors[0]
		},
		getServiceTermsRead (state){
			state.serviceTermsReadBusy = true;
		},
		getServiceTermsReadSuccess (state,response){
			state.serviceTermsReadBusy = false;
			state.serviceTermsRead = response.data;
		},
		getServiceTermsReadFail (state){
			state.serviceTermsReadBusy = false;
		},
	},
	actions: {
		getServiceTerms: (store) => {
			store.commit('getServiceTerms')
			return api.getServiceTerms()
				.then(
					response => {
						store.commit('getServiceTermsSuccess',response)
						return response;
					},
					error => {
						store.commit('getServiceTermsFail', error)
						return error;
					}
				)
		},
		getPrivacyPolicyTerms: (store) => {
			store.commit('getPrivacyPolicyTerms')
			return api.getPrivacyPolicyTerms()
				.then(
					response => {
						store.commit('getPrivacyPolicyTermsSuccess',response)
						return response;
					},
					error => {
						store.commit('getPrivacyPolicyTermsFail', error)
						return error;
					}
				)
		},
		downloadServiceTerms: (store) => {
			store.commit('downloadServiceTerms')
			return api.downloadServiceTerms()
				.then(
					response => {
						store.commit('downloadServiceTermsSuccess',response)
						return response;
					},
					error => {
						store.commit('downloadServiceTermsFail', error)
						return error;
					}
				)
		},
		downloadPrivacyPolicyTerms: (store) => {
			store.commit('downloadPrivacyPolicyTerms')
			return api.downloadPrivacyPolicyTerms()
				.then(
					response => {
						store.commit('downloadPrivacyPolicyTermsSuccess',response)
						return response;
					},
					error => {
						store.commit('downloadPrivacyPolicyTermsFail', error)
						return error;
					}
				)
		},
		acceptTerms: (store, { termsAccepted }) => {
			store.commit('acceptTerms')
			return api.acceptTerms(termsAccepted)
				.then(
					response => {
						store.commit('acceptTermsSuccess',response)
						return response;
					},
					error => {
						store.commit('acceptTermsFail', error)
						return error;
					}
				)
		},
		getCookiesPrivacyPolicyTerms: (store) => {
			store.commit('getCookiesPrivacyPolicyTerms')
			return api.getCookiesPrivacyPolicyTerms()
				.then(
					response => {
						store.commit('getCookiesPrivacyPolicyTermsSuccess',response)
						return response;
					},
					error => {
						store.commit('getCookiesPrivacyPolicyTermsFail', error)
						return error;
					}
				)
		},
		getServiceTermsRead: (store) => {
			store.commit('getServiceTermsRead')
			return api.getServiceTermsRead()
				.then(
					response => {
						store.commit('getServiceTermsReadSuccess',response)
						return response;
					},
					error => {
						store.commit('getServiceTermsReadFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		serviceTermsBusy: state => state.serviceTermsBusy,
		serviceTerms: state => state.serviceTerms,
		cookiesPrivacyPolicyTermsBusy: state => state.cookiesPrivacyPolicyTermsBusy,
		cookiesPrivacyPolicyTerms: state => state.cookiesPrivacyPolicyTerms,
		privacyPolicyTermsBusy : state => state.privacyPolicyTermsBusy,
		privacyPolicyTerms : state => state.privacyPolicyTerms,
		downloadServiceTermsBusy : state => state.downloadServiceTermsBusy,
		downloadPrivacyPolicyTermsBusy : state => state.downloadPrivacyPolicyTermsBusy,
		acceptTermsBusy : state => state.acceptTermsBusy,
		acceptTermsError : state => state.acceptTermsError,
		acceptTermsSuccess : state => state.acceptTermsSuccess,
		acceptTermsUrl : state => state.acceptTermsUrl,
		serviceTermsReadBusy: state => state.serviceTermsReadBusy,
		serviceTermsRead: state => state.serviceTermsRead,
	},
}