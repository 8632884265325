<template>
	<div class="view-content__wrapper">
		<div class="view-content">
			<div class="flex-grow-1">
				<div class="mb-6 headline-1">
					{{ $t("choose_user.choose_user") }}
				</div>
				<Alert
					v-if="getError"
					class="mt-3" />
				<div
					v-for="(email, id) in users"
					:key="id"
					class="mt-6 d-flex align-center justify-space-between user">
					<e-avatar
						:letters="email.slice(0, 2).toUpperCase()"
						:title="email"
						:size="44" />
					<!-- TODO remove when avatar has title prop again -->
					<span class="callout-3">
						{{ email }}
					</span>
					<!-- -->
					<e-button
						class="callout-3"
						:block="false"
						:loading="selected == id"
						variant="secondary"
						@click="choose(id)">
						{{ $t("choose_user.choose") }}
					</e-button>
				</div>
				<div class="text-center mt-7">
					<e-button
						variant="link"
						small=""
						:block="false"
						:to="'/' + $i18n.locale">
						{{ $t("forgot_password.back_to_frontpage") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import { selectUser, } from "../api/index.js";
import { mapGetters, mapState } from "vuex";
import Alert from "@/components/Alert.vue";
export default {
	components: {
		Alert,
	},
	data () {
		return {
			selected: -1,
			users: {},
			colorCache: {},
		};
	},
	computed:{
		...mapGetters([
			'chooseUserBusy',
			'chooseUserError',
			'chooseUserSuccess',
			'chooseUserErrors',
			'getError',
		]),
		...mapState ([
			'miniView',
		]),
	},
	mounted () {
		if (this.$route.params.users) {
			this.users = this.$route.params.users;
		}else{
			this.$router.push({
				name: "login",
			});
		}
	},
	methods: {
		choose (id) {
			this.selected = id;
			
			this.$store.dispatch('chooseUser', { id }).then(	
				response => {
					if(this.chooseUserSuccess && response.body.url !== ""){
						if (this.miniView) {
							this.postLoginMessage('success')
							return
						}
						window.top.location.href = response.body.url;
					}
					else if(this.chooseUserErrors == 'user_not_found'){
						this.$store.commit("changeError",
							"forgot_password.user_not_found");
						this.$store.commit("showError",true);
						this.selected = -300;
					}
					else if(this.chooseUserError && this.chooseUserErrors){
						if(this.$te("choose_user." + this.chooseUserErrors)){
							this.$store.commit("changeError",
								("choose_user." + this.chooseUserErrors));
							this.$store.commit("showError",true);
						}
						else{
							this.$store.commit("changeError",
								("repeating_errors." + this.chooseUserErrors));
							this.$store.commit("showError",true);
						}
					}
				}

			)
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
