<template>
	<div
		class="welcome-view d-flex flex-column mx-auto"
		style="max-width: 53.8rem">
		<img
			class="mb-8 mx-auto fluid-img"
			src="../assets/welcome.svg"
			alt="welcome"
			style="max-width: 38.4rem">
		<div class="d-flex flex-column align-center mb-8">
			<span class="headline-1 text-center mb-1">
				{{ $t('welcome.title') }}
			</span>
			<span
				class="body-2 px-4 text-center"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
				{{ $t('welcome.description_1') }}
				<br>
				{{ $t('welcome.description_2') }}
			</span>
		</div>
		<div class="mx-auto">
			<e-button
				block="false"
				variant="primary"
				size="large"
				:href="`${appUrl}/#/${$i18n.locale}`"
				large>
				{{ $t('welcome.go_to_login') }}
			</e-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getEnv } from '../env';
export default {
	data (){
		return{
			prevShowIlustration: false,
			appUrl: getEnv('VUE_APP_FRONTEND_APP_URL'),
		}
	},
	computed: {
		...mapGetters([
			'showIlustration',
		]),
	},
	mounted (){
		this.prevShowIlustration = this.showIlustration
		this.$store.commit("showIlustration",false); //hide ilustration
	},
	beforeDestroy (){
		this.prevShowIlustration ? this.$store.commit("showIlustration", true) : null //show ilustration
	},
}
</script>