<template>
	<div class="view-content__wrapper">
		<div class="view-content d-block d-md-flex align-center justify-center mx-auto">
			<div>
				<div
					v-if="type == 'registration'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-danger danger200--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						<span v-if="$te('registration_failed.' + error)">{{ $t('registration_failed.' + error) }}</span>
						<span v-if="$te('repeating_errors.' + error)">{{ $t('repeating_errors.' + error) }}</span>
					</p>
				</div>
				<div
					v-if="type == 'activation'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-danger danger200--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t('registration_failed.registration_failed') }}
					</p>
					<p
						class="body-3 text-center mb-6"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
						{{ $t('registration_failed.activation_failed') }}
					</p>
				</div>
				<div
					v-if="type == 'schoolRegister'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-danger danger200--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t('school_registration.school_registration_failed') }}
					</p>
				</div>
				<div
					v-if="type == 'reset'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-danger danger200--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t('password_recovery.reset_failed') }}
					</p>
					<p
						class="body-3 text-center mb-6"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
						<span v-if="$te('password_recovery.' + message)">{{ $t('password_recovery.' + message) }}</span>
						<span v-if="$te('repeating_errors.' + message)">{{ $t('repeating_errors.' + message) }}</span>
					</p>
				</div>
				<div
					class="text-center mt-4">
					<e-button
						variant="link"
						small=""
						:block="false"
						:to="'/' + $i18n.locale">
						{{ $t("forgot_password.back_to_frontpage") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			email: "",
			type: undefined,
			error: "",
			message: undefined,
			hash: undefined,
		};
	},
	mounted () {
		this.email = this.$route.params.email;
		this.error = this.$route.params.error;
		this.message = this.$route.params.message;
		if (this.$route.params.type) {
			this.type = this.$route.params.type;
		}
		else if(this.$route.query.type){
			this.type = this.$route.query.type;
		}
		//navigate to main page, if no type
		if(this.type == undefined){
			this.$router.replace({
				name: "login",
			});
		}
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
