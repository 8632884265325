import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'

import { i18n } from '../../i18n'
Vue.use(Vuex)

export default{
	state: {
		smartLoginBusy: false,
		smartError: false,
		smartLoggedIn: false,
		smartLoginUrl: "",
		smartShowRecaptcha: false,
		smartSuccess: false,
		smartErrors: "",
		smartCodeSent: false,
		smartCode: "",
		smartAccounts: undefined,
		smartUser: undefined,
		smartPing: false,
		smartPingBusy: false,
		smartTermsRequired: false,
	},
	mutations: {
		loginSmartUser (state){
			state.smartLoginBusy = true;
		},
		registerSmartUser (state){
			state.smartLoginBusy = true;
		},
		resetSmartState (state){
			state.smartLoginBusy = false;
			state.smartError = false;
			state.smartLoggedIn = false;
			state.smartLoginUrl = false;
			state.smartShowRecaptcha = false;
			state.smartSuccess = false;
			state.smartErrors = "";
			state.smartCodeSent = false;
			state.smartCode = "";
			state.smartAccounts = undefined;
			state.smartUser = undefined;
			state.smartPing = true;
		},
		loginSmartUserSuccess (state,response){
			state.smartLoginBusy = false;
			state.smartSuccess = response.body.success;
			state.smartPing = true;
			state.smartError = response.body.error;
			state.smartErrors = response.body.errors;
			state.smartShowRecaptcha = response.body.show_captcha;
			if(response.body.success){
				state.smartCode = response.body.verification_code;
				state.smartCodeSent = true;
			}
		},
		registerSmartUserSuccess (state,response){
			state.smartLoginBusy = false;
			state.smartSuccess = response.body.success;
			state.smartPing = true;
			state.smartShowRecaptcha = response.body.show_captcha;
			if(response.body.success){
				state.smartCode = response.body.verification_code;
				state.smartCodeSent = true;
			}
		},
		loginSmartUserFail (state, error){
			state.smartLoginBusy = false;
			state.smartError = true;
			state.smartShowRecaptcha = error.body.show_captcha;
			state.smartErrors = error.body.errors;
		},
		registerSmartUserFail (state, error){
			state.smartLoginBusy = false;
			state.smartError = true;
			state.smartShowRecaptcha = error.body.show_captcha;
			state.smartErrors = error.body.errors;
			
			/*
			state.commit(
				"changeError",
				i18n.t("smart_id_login.submit_error")
			);
			state.commit("showError", true);
			*/
		},
		pingSmart (state){
			state.smartPingBusy = true;
		},
		pingSmartSuccess (state,response){
			state.smartPingBusy = false;
			state.smartTermsRequired = response.body.terms_acception_required;
			state.smartSuccess = response.body.success;
			if(state.smartSuccess && !state.smartTermsRequired){
				state.smartPing = false;
				if(response.body.login_method == 'smart_id' && response.body.url){
					state.smartLoggedIn = true;
					state.smartLoginUrl = response.body.url;
				}
			}
			if(state.smartTermsRequired){
				state.smartPing = false;
			}
			state.smartLoginBusy = false;
			state.smartAccounts = response.body.accounts;
			state.smartUser = response.body.user;
			if(response.body.errors){
				state.smartError = true;
				state.smartErrors = response.body.errors;
				state.smartCodeSent = false;
			}
		
		},
		pingSmartFail (state,error){
			state.smartPingBusy = false;
			state.smartError = true;
			state.smartErrors = error.body.errors;
			state.smartPing = false;
			state.smartLoginBusy = false;
			state.smartShowRecaptcha = error.body.show_captcha;
			state.smartCodeSent = false;
			if(i18n.te("smart_id_login." + state.smartErrors)){
				this.commit("changeError",
					("smart_id_login." + state.smartErrors));
				this.commit("showError",true);
			}
			else{
				this.commit("changeError",
					("repeating_errors." + state.smartErrors));
				this.commit("showError",true);
			}
		},
		cancelSmartPing (state){
			state.smartCodeSent = false;
			state.smartPing = false;
		},
	},
	actions: {
		loginCardSmart: (store, { country, personalCode, recaptcha }) => {
			store.commit('loginSmartUser')
			return api.loginCardSmart(country, personalCode, recaptcha)
				.then(
					response => {
						store.commit('loginSmartUserSuccess',response)
						return response;
					},
					error => {
						store.commit('loginSmartUserFail', error)
						return error;
					},
				)
		},
		registerCardSmart: (store, { country, personalCode, recaptcha }) => {
			store.commit('registerSmartUser')
			return api.registerCardSmart(country, personalCode, recaptcha)
				.then(
					response => {
						store.commit('registerSmartUserSuccess',response)
						return response;
					},
					error => {
						store.commit('registerSmartUserFail', error)
						return error;
					},
				)
		},
		loginPingSmart : (store) => {
			store.commit('pingSmart');
			return api.loginCardSmartCheck()
				.then(
					response => {
						store.commit('pingSmartSuccess',response)
						return response;
					},
					error => {
						store.commit('pingSmartFail',error)
						return error;
					},
				)
		},
		registerPingSmart : (store) => {
			store.commit('pingSmart');
			return api.registerCardSmartCheck()
				.then(
					response => {
						store.commit('pingSmartSuccess',response)
						return response;
					},
					error => {
						store.commit('pingSmartFail',error)
						return error;

					},
				)
		},
	},
	getters: {
		smartLoginBusy: state => state.smartLoginBusy,
		smartError: state => state.smartError,
		smartErrors: state => state.smartErrors,
		smartLoggedIn: state => state.smartLoggedIn,
		smartLoginUrl: state => state.smartLoginUrl,
		smartShowRecaptcha: state => state.smartShowRecaptcha,
		smartSuccess: state => state.smartSuccess,
		smartCodeSent: state => state.smartCodeSent,
		smartCode: state => state.smartCode,
		smartAccounts: state => state.smartAccounts,
		smartUser: state => state.smartUser,
		smartPing: state => state.smartPing,
		smartPingBusy: state => state.smartPingBusy,
		smartTermsRequired: state => state.smartTermsRequired,
	},
}