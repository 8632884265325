/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
import { extend, configure, ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, min, alpha, regex, numeric, alpha_spaces, alpha_dash, max } from "vee-validate/dist/rules";
import { i18n } from "./i18n";
import Vue from 'vue'



configure({
	bails: false,
	defaultMessage: (field, values) => {
		// override the field name.
		values._field_ = i18n.t(`fields.${field}`);

		return i18n.t(`validation.${values._rule_}`, values);
	},
});


  
// Install required rule and message.
extend("required", required);
extend("alpha", alpha);
extend("alpha_spaces", alpha_spaces);
extend("alpha_dash", alpha_dash);
extend("regex", regex);
extend("email", email);
extend("min", min);
extend("numeric", numeric);
extend("max", max);

//custom estonian ID code validation
extend("handleIDCode",{
	validate: value => {
		
		var idCodeArr = Array.from(value).map(Number);
		if (idCodeArr.length !== 11) {
			return false;
		}
		var wages1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
		var sum1 = 0;
		for (var i = 0; i !== 10; i++) {
			sum1 += idCodeArr[i] * wages1[i];
		}
		var module1 = sum1 % 11;
		if (module1 !== 10) {
			return module1 === idCodeArr[10];
		}
		var wages2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];
		var sum2 = 0;
		for (var j = 0; j !== 10; j++) {
			sum2 += idCodeArr[j] * wages2[j];
		}
		var module2 = sum2 % 11;
		if (module2 !== 10) {
			return module2 === idCodeArr[10];
		}
		return idCodeArr[10] === 0;
	},
});
//check gender if valid EE id code
extend('checkGender', {
	params: ['idCode','issuer'],
	validate (value, { idCode,issuer }) {
		var gender = "";
		if(issuer == 'EE'){
			var idCodeArr = Array.from(idCode).map(Number);
			if (idCodeArr[0] % 2 === 0) {
			//Female
				gender = 1;
			} else {
			//Male
				gender = 0;
			}
			return value == gender;
		}else{
			return true;
		}
	},
	message: 'wrong gender',
});
//check date of birth if valid EE id code
extend('checkDate', {
	params: ['idCode','issuer'],
	validate (value, { idCode,issuer }) {
		if(issuer == 'EE'){
			var idCodeArr = Array.from(idCode).map(Number);
			var year;
			if (idCodeArr[0] === 1 || idCodeArr[0] === 2) {
				year = "18";
			} else if (idCodeArr[0] === 3 || idCodeArr[0] === 4) {
				year = "19";
			} else if (idCodeArr[0] === 5 || idCodeArr[0] === 6) {
				year = "20";
			}
			var correctYear =
            idCodeArr[5] +
            "" +
            idCodeArr[6] +
            "." +
            idCodeArr[3] +
            "" +
            idCodeArr[4] +
            "." +
            year +
            "" +
            idCodeArr[1] +
            "" +
			idCodeArr[2];
			return value == correctYear;
		}else{
			return true;
		}
	},
	
});
//password confirmation
extend('password',{
	params: ['target'],
	validate (value, { target }) {
		return value === target;
	},
});
//email confirmation
extend('emailConfirmation',{
	params: ['target'],
	validate (value, { target }) {
		return value === target;
	},
});
//password strength
extend("includesNumber", {
	validate: value => {
		return /\d/.test(value);
	},
});
extend("includesLowercase", {
	validate: value => {
		return /[a-z]/.test(value);
	},
});
extend("includesUppercase", {
	validate: value => {
		return /[A-Z]/.test(value);
	},
});
//custom new password rule
extend("passwordStrength", {
	validate: value => {
		if ((/[a-z]/.test(value) && /\d/.test(value) && /[A-Z]/.test(value) ||
			/[a-z]/.test(value) && /\d/.test(value) && /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value) || 
			/\d/.test(value) && /[A-Z]/.test(value) && /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value) ||
			/[a-z]/.test(value) && /[A-Z]/.test(value) && /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(value)))
		{
			return true;
		}
		else{
			return false;
		}
		
	},
});
//date of birth
extend("dateOfBirth", {
	validate: value => {
		const dateFormat = new RegExp(/^\d{1,2}\.\d{1,2}\.\d{4}$/);
		if(dateFormat.test(value)){
			var splitDate = value.split(".");
			var day = splitDate[0];
			var month = splitDate[1];
			var year = splitDate[2];
			var leapYear = ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
			var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
			var days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
			if (months.includes(month) && days.includes(day)) {
				if ((month == '02' && day == '29' && leapYear == false) || (month == '02' && day == '30') || (month == '02' && day == '31') || (month == '04' && day == '31') || (month == '06' && day == '31') || (month == '09' && day == '31') || (month == '11' && day == '31')) {
					return false;
				} else {
					var GivenDate = year + '-' + month + '-' + day;
					var CurrentDate = new Date();
					GivenDate = new Date(GivenDate);
					if (GivenDate > CurrentDate) {
						return false;
					} else {
						return true;
					}
				}
			} else {
				return false;
			}
		}else{
			return false
		}
		
	},
});



Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);