<template>
	<div class="mb-0 d-flex flex-column align-center">
		<div
			class="d-flex mb-6"
			style="width: 100%;">
			<e-tooltip
				v-for="(method, index) in thirdPartyMethods"
				:key="index"
				:disabled="$vuetify.breakpoint.mobile"
				top>
				<template #activator="{on}">
					<e-button
						v-if="method.name == 'passkey'"
						style="min-width: 0!important; flex-basis: 0; flex-grow: 1;"
						class="elevation-1 flex-grow-1"
						:class="{'mr-2': index + 1 < thirdPartyMethods.length}"
						variant="tertiary"
						size="large"
						large
						@click="passkeyLogin"
						v-on="on">
						<img
							:src="method.name == 'passkey' && $vuetify.theme.dark ? require(`../assets/${method.logo}-white.svg`) : require(`../assets/${method.logo}.svg`)"
							alt="logo"
							height="19px">
					</e-button>
					<e-button
						v-else
						style="min-width: 0!important; flex-basis: 0; flex-grow: 1;"
						class="elevation-1 flex-grow-1"
						:class="{'mr-2': index + 1 < thirdPartyMethods.length}"
						:href="register ? method.registerUrl : method.loginUrl"
						:target="iframeView ? '_parent' : '_self'"
						variant="tertiary"
						size="large"
						large
						v-on="on">
						<img
							:src="method.name == 'passkey' && $vuetify.theme.dark ? require(`../assets/${method.logo}-white.svg`) : require(`../assets/${method.logo}.svg`)"
							alt="logo"
							height="19px">
					</e-button>
				</template>
				{{ method.displayName }}
			</e-tooltip>
		</div>
		<div
			class="d-flex align-center"
			style="width: 100%;">
			<div class="line" />
			<span
				class="body-3 mx-3"
				style="font-weight: 500!important;"
				:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
				{{ $t('third_party_login.or') }}
			</span>
			<div class="line" />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getEnv } from '../env';
export default {
	props:{
		register: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			availableMethods: [
				{ name: 'passkey', displayName: 'Passkey', logo: 'passkey-logo', loginUrl: '/oauth/passkey/redirect', registerUrl: '/oauth/passkey/register/redirect' },
				{ name: 'google', displayName: 'Google', logo: 'google-logo', loginUrl: '/oauth/google/redirect', registerUrl: '/oauth/google/register/redirect' },
				{ name: 'microsoft', displayName: 'Microsoft', logo: 'microsoft-logo', loginUrl: '/oauth/microsoft/redirect', registerUrl: '/oauth/microsoft/register/redirect' },
				{ name: 'eliis', displayName: 'ELIIS', logo: 'eliis-logo', loginUrl: '/oauth/eliis/redirect', registerUrl: '/oauth/eliis/register/redirect' },
			],
			allowedMethods: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(", "),
			thirdPartyMethods: [],
		};
	},
	computed: {
		...mapGetters([
			'iframeView',
		]),
		...mapState([
			'passkeysLoginOptionsBusy',
			'passkeysLoginOptionsSuccess',
			'passkeysLoginOptionsResponse',
		]),
	},
	mounted (){
		this.handleAvailableMethods()
	},
	methods: {
		async handleAvailableMethods (){
			this.thirdPartyMethods = this.availableMethods.filter(method => this.allowedMethods.includes(method.name))
			//hide passkey for registration & case when browser does not support webauthn
			const browserSupportsWebAuthn = await window.SimpleWebAuthnBrowser.browserSupportsWebAuthn()
			if(this.register || !browserSupportsWebAuthn){
				this.thirdPartyMethods = this.thirdPartyMethods.filter(method => method.name !== 'passkey')
			}
		},
		async passkeyLogin (){
			const resp = await this.$store.dispatch('getPasskeysLoginOptions')
			
			let asseResp;
			try {
				if(resp.body && resp.body.error){
					const errorMessage = resp.body.error
					this.$eToast.error(this.$i18n.t(`passkey_errors.${errorMessage}`))
					return
				}
				else{
					asseResp = await window.SimpleWebAuthnBrowser.startAuthentication(await resp.body)
				}
			}
			catch (error) {
				this.$eToast.error(this.$i18n.t('third_party_login.passkey_authentication_failed'))
				throw error
			}

			const verificationResp = await this.$store.dispatch('getPasskeysVerification', { passkeysAuthorizationResp : asseResp })
			try{
				if(verificationResp.body && verificationResp.body.success){
					window.top.location = verificationResp.body.url
				}
				else if(verificationResp.body && verificationResp.body.error){
					const errorMessage = verificationResp.body.error
					this.$eToast.error(this.$i18n.t(`passkey_errors.${errorMessage}`))
					return
				}
			}	
			catch (error) {
				this.$eToast.error(this.$i18n.t('third_party_login.passkey_verification_failed'));
				throw error
			}
		},
	},
};
</script>