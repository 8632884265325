import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'

Vue.use(Vuex)

export default{
	state: {
		setupAccountBusy: false,
		setupAccountError: false,
		setupAccountErrors: "",
		setupAccountMessage: "",
		setupAccountSuccess: false,
		setupAccountCountry: undefined,
		setupAccountHash: undefined,
		proceedRegistrationErrors: "",
		proceedRegistrationSuccess: false,
		showPhoneNumberModal: false,
		registerEmailValidateSuccess: false,
		registerEmailValidateError: false,
		registerEmailValidateBusy: false,
		registerEmailValidateErrors: "",
		registerPhoneVerifyRequestBusy: false,
		registerPhoneVerifyRequestSuccess: false,
		registerPhoneVerifyRequestTimeout: 0,
		registerPhoneVerifyRequestError: false,
		registerPhoneVerifyRequestErrors: "",
		registerPhoneVerifyCodeBusy: false,
		registerPhoneVerifyCodeSuccess: false,
		registerPhoneVerifyCodeError: false,
		registerPhoneVerifyCodeErrors: "",
		getTeacherInviteDataBusy: false,
		getTeacherInviteDataSuccess: false,
		getTeacherInviteDataErrors: "",
		teacherInviteEmail: undefined,
	},
	mutations: {
		setupAccount (state){
			state.setupAccountBusy = true;
		},
		setupAccountSuccess (state,response){
			state.setupAccountBusy = false;
			if(response.body.success){
				state.setupAccountSuccess = true;
				state.setupAccountMessage = response.body.message;
				state.setupAccountHash = response.body.hash;
			}else{
				state.setupAccountError = true;
			}
		},
		setupAccountFail (state,error){
			state.setupAccountBusy = false;
			state.setupAccountError = true;
			if(error.body.errors){
				state.setupAccountErrors = error.body.errors;
			}
		},
		getCountrySuccess (state,response){
			if (response.status == 200) {
				state.setupAccountCountry = response.body.country;
			}
		},
		getCountryFail (){
			return false;
		},
		proceedRegistrationSuccess (state,response){
			state.proceedRegistrationSuccess = response.body.success;
		},
		proceedRegistrationFail (state,error){
			state.proceedRegistrationSuccess = error.body.success;
			state.proceedRegistrationErrors = error.body.errors;
		},
		setPhoneNumberModal (state, value){
			state.showPhoneNumberModal = value;
		},
		registerEmailValidate (state){
			state.registerEmailValidateBusy = true;
			state.registerEmailValidateSuccess = false;
			state.registerEmailValidateError = false;
			state.registerEmailValidateErrors = "";
		},
		registerEmailValidateSuccess (state,response){
			state.registerEmailValidateBusy = false;
			if(response.body.success){
				state.registerEmailValidateSuccess = true;
			}else{
				state.registerEmailValidateError = true;
			}
		},
		registerEmailValidateFail (state,error){
			state.registerEmailValidateBusy = false;
			state.registerEmailValidateError = true;
			if(error.body.errors){
				state.registerEmailValidateErrors = error.body.errors;
			}
		},
		registerPhoneVerifyRequest (state){
			state.registerPhoneVerifyRequestBusy = true;
			state.registerPhoneVerifyRequestSuccess = false;
			state.registerPhoneVerifyRequestError = false;
			state.registerPhoneVerifyCodeError = false;
			state.registerPhoneVerifyCodeErrors = "";
			state.registerPhoneVerifyRequestErrors = "";
			state.registerPhoneVerifyRequestTimeout = 0;
		},
		registerPhoneVerifyRequestSuccess (state, response){
			state.registerPhoneVerifyRequestBusy = false;
			if(response.body.success){
				state.registerPhoneVerifyRequestSuccess = true;
			}else{
				state.registerPhoneVerifyRequestError = true;
			}
			if(response.body.timeout){
				state.registerPhoneVerifyRequestTimeout = response.body.timeout;
			}
		},
		registerPhoneVerifyRequestFail (state,error){
			state.registerPhoneVerifyRequestBusy = false;
			state.registerPhoneVerifyRequestError = true;
			if(error.body.errors){
				state.registerPhoneVerifyRequestErrors = error.body.errors;
			}
			if(error.body.timeout){
				state.registerPhoneVerifyRequestTimeout = error.body.timeout;
			}
		},
		registerPhoneVerifyCode (state){
			state.registerPhoneVerifyCodeBusy = true;
			state.registerPhoneVerifyCodeSuccess = false;
			state.registerPhoneVerifyCodeError = false;
			state.registerPhoneVerifyCodeErrors = "";
			state.registerPhoneVerifyRequestError = false;
			state.registerPhoneVerifyRequestErrors = "";
		},
		registerPhoneVerifyCodeSuccess (state, response){
			state.registerPhoneVerifyCodeBusy = false;
			if(response.body.success){
				state.registerPhoneVerifyCodeSuccess = true;
			}else{
				state.registerPhoneVerifyCodeError = true;
			}
		},
		registerPhoneVerifyCodeFail (state,error){
			state.registerPhoneVerifyCodeBusy = false;
			state.registerPhoneVerifyCodeError = true;
			if(error.body.errors){
				state.registerPhoneVerifyCodeErrors = error.body.errors;
			}
		},
		setRegisterPhoneVerifyCodeSuccess (state,value){
			state.registerPhoneVerifyCodeSuccess = value
		},
		clearPhoneNumberErrors (state){
			state.registerPhoneVerifyCodeError = false;
			state.registerPhoneVerifyCodeErrors = "";
			state.registerPhoneVerifyRequestError = false;
			state.registerPhoneVerifyRequestErrors = "";
		},
		getTeacherInviteData (state){
			state.getTeacherInviteDataBusy = true;
			state.getTeacherInviteDataSuccess = false;
			state.getTeacherInviteDataErrors = "";
		},
		getTeacherInviteDataSuccess (state, response){
			state.getTeacherInviteDataBusy = false;
			state.getTeacherInviteDataSuccess = response.body.success
			state.teacherInviteEmail = response.body.email
		},
		getTeacherInviteDataFail (state,error){
			state.getTeacherInviteDataBusy = false;
			state.getTeacherInviteDataSuccess = error.body.success;
			if(error.body.errors){
				state.getTeacherInviteDataErrors = error.body.errors;
			}
		},
	},
	actions: {
		setupAccount: (store, { firstName,
			lastName,
			idCode,
			issuer,
			dateOfBirth,
			gender,
			email,
			emailConfirmation,
			password,
			passwordConfirmation,
			wishPartnerAds,
			countryCode,
			phoneNumber,
			country,
			county,
			city,
			street,
			postalIndex,
			thirdPartyHash,
			inviteType,
			inviteHash }) => {
			store.commit('setupAccount')
			return api.registerUser(firstName,
				lastName,
				idCode,
				issuer,
				dateOfBirth,
				gender,
				email,
				emailConfirmation,
				password,
				passwordConfirmation,
				wishPartnerAds,
				countryCode,
				phoneNumber,
				country,
				county,
				city,
				street,
				postalIndex,
				thirdPartyHash,
				inviteType,
				inviteHash)
				.then(
					response => {
						store.commit('setupAccountSuccess',response)
						return response;
					},
					error => {
						store.commit('setupAccountFail', error)
						return error;
					},
				)
		},
		proceedRegistration: (store, { hash }) => {
			return api.createAccSameId(hash)
				.then(
					response => {
						store.commit('proceedRegistrationSuccess',response)
						return response;
					},
					error => {
						store.commit('proceedRegistrationFail', error)
						return error;
					},
				)
		},
		getCountry: (store) => {
			return api.getCountry()
				.then(
					response => {
						store.commit('getCountrySuccess',response)
						return response;
					},
					error => {
						store.commit('getCountryFail', error)
						return error;
					},
				)
		},
		registerEmailValidate: (store, { email }) => {
			store.commit('registerEmailValidate')
			return api.registerEmailValidate(email)
				.then(
					response => {
						store.commit('registerEmailValidateSuccess',response)
						return response;
					},
					error => {
						store.commit('registerEmailValidateFail', error)
						return error;
					},
				)
		},
		registerPhoneVerifyRequest: (store, { countryCode, phoneNumber, token }) => {
			store.commit('registerPhoneVerifyRequest')
			return api.registerPhoneVerifyRequest(countryCode, phoneNumber, token)
				.then(
					response => {
						store.commit('registerPhoneVerifyRequestSuccess', response)
						return response;
					},
					error => {
						store.commit('registerPhoneVerifyRequestFail', error)
						return error;
					},
				)
		},
		registerPhoneVerifyCode: (store, { countryCode, phoneNumber, code }) => {
			store.commit('registerPhoneVerifyCode')
			return api.registerPhoneVerifyCode(countryCode, phoneNumber, code)
				.then(
					response => {
						store.commit('registerPhoneVerifyCodeSuccess', response)
						return response;
					},
					error => {
						store.commit('registerPhoneVerifyCodeFail', error)
						return error;
					},
				)
		},
		getTeacherInviteData: (store, { hash }) => {
			store.commit('getTeacherInviteData')
			return api.getTeacherInviteData(hash)
				.then(
					response => {
						store.commit('getTeacherInviteDataSuccess', response)
						return response;
					},
					error => {
						store.commit('getTeacherInviteDataFail', error)
						return error;
					},
				)
		},
	},
    
	getters: {
		setupAccountBusy: state => state.setupAccountBusy,
		setupAccountError: state => state.setupAccountError,
		setupAccountErrors: state => state.setupAccountErrors,
		setupAccountMessage: state => state.setupAccountMessage,
		setupAccountSuccess: state => state.setupAccountSuccess,
		setupAccountCountry: state => state.setupAccountCountry,
		setupAccountHash: state => state.setupAccountHash,
		proceedRegistrationErrors: state => state.proceedRegistrationErrors,
		proceedRegistrationSuccess: state => state.proceedRegistrationSuccess,
		showPhoneNumberModal: state => state.showPhoneNumberModal,
		registerEmailValidateSuccess: state => state.registerEmailValidateSuccess,
		registerEmailValidateError: state => state.registerEmailValidateError,
		registerEmailValidateBusy: state => state.registerEmailValidateBusy,
		registerEmailValidateErrors: state => state.registerEmailValidateErrors,
		registerPhoneVerifyRequestSuccess: state => state.registerPhoneVerifyRequestSuccess,
		registerPhoneVerifyRequestError: state => state.registerPhoneVerifyRequestError,
		registerPhoneVerifyRequestBusy: state => state.registerPhoneVerifyRequestBusy,
		registerPhoneVerifyRequestErrors: state => state.registerPhoneVerifyRequestErrors,
		registerPhoneVerifyRequestTimeout: state => state.registerPhoneVerifyRequestTimeout,
		registerPhoneVerifyCodeSuccess: state => state.registerPhoneVerifyCodeSuccess,
		registerPhoneVerifyCodeError: state => state.registerPhoneVerifyCodeError,
		registerPhoneVerifyCodeBusy: state => state.registerPhoneVerifyCodeBusy,
		registerPhoneVerifyCodeErrors: state => state.registerPhoneVerifyCodeErrors,
		getTeacherInviteDataBusy: state => state.getTeacherInviteDataBusy,
		getTeacherInviteDataSuccess: state => state.getTeacherInviteDataSuccess,
		getTeacherInviteDataErrors: state => state.getTeacherInviteDataErrors,
		teacherInviteEmail: state => state.teacherInviteEmail,
	},
}