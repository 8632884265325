import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		cardBusy: false,
		cardError: false,
		cardSuccess: false,
		cardErrors: undefined,
		cardMultipleAccounts: false,
		cardAccounts: undefined,
		cardUser: undefined,
	},
	mutations: {
		//login
		cardLogin (state){
			state.cardBusy = true;
		},
		cardLoginSuccess (state,response){
			state.cardBusy = false;
			if (response.body.success && response.body.multiple_accounts) {
				state.cardSuccess = true;
				state.cardAccounts = response.body.accounts;
				state.cardMultipleAccounts = true;
			}
			else if(response.body.success && response.body.login_method == "id_card"){
				state.cardSuccess = true;
				if (this.state.miniView) {
					this.state.miniViewFunction('success')
					return
				}
				window.top.location.href = response.body.url;
			}
		},
		cardLoginFail (state,error){
			state.cardBusy = false;
			state.cardError = true;
			state.cardErrors = error.body.errors;
		},
		//registration
		cardRegister (state){
			state.cardBusy = true;
		},
		cardRegisterSuccess (state,response){
			state.cardBusy = false;
			if (response.body.success && response.body.multiple_accounts) {
				state.cardSuccess = true;
				state.cardAccounts = response.body.accounts;
				state.cardMultipleAccounts = true;
			}
			else if(response.body.success && response.body.user){
				state.cardUser = response.body.user;
			}
		},
		cardRegisterFail (state,error){
			state.cardBusy = false;
			state.cardError = true;
			state.cardErrors = error.body.errors;
		},
	},
	actions: {
		cardLogin: (store) => {
			store.commit('cardLogin')
			return api.loginIdCard()
				.then(
					response => {
						store.commit('cardLoginSuccess',response)
						return response;
					},
					error => {
						store.commit('cardLoginFail', error)
						return error;
					}
				)
		},
		cardRegister: (store) => {
			store.commit('cardRegister')
			return api.registerIdCard()
				.then(
					response => {
						store.commit('cardRegisterSuccess',response)
						return response;
					},
					error => {
						store.commit('cardRegisterFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		cardBusy: state => state.cardBusy,
		cardError: state => state.cardError,
		cardSuccess: state => state.cardSuccess,
		cardErrors: state => state.cardErrors,
		cardMultipleAccounts: state => state.cardMultipleAccounts,
		cardAccounts: state => state.cardAccounts,
		cardUser: state => state.cardUser,
	},
}