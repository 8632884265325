<template>
	<div class="view-content__wrapper">
		<div class="view-content">
			<div
				v-if="activeItem == 'password'"
				class="mb-6 headline-1">
				{{ $t("setup_account.identification") }}
			</div>
			<div
				v-else
				class="mb-6 headline-1">
				{{ $t(`menu-items.register_with_${activeItem}`) }}
			</div>
			<Alert v-if="getError" />
			<transition
				name="fade"
				mode="out-in">
				<div v-if="activeItem == 'password'">
					<ThirdParty
						v-if="(thirdPartyAllowedMethods && thirdPartyAllowedMethods.length > 0) && (mobileView ? thirdPartyAllowedForMobile : true)"
						:register="true" />
					<Password :register="true" />
				</div>
				<Mobile
					v-if="activeItem == 'mobile_id'"
					:register="true" />
				<Smart
					v-if="activeItem == 'smart_id'"
					:register="true" />
				<Har
					v-if="activeItem == 'har_id'"
					:register="true" />
				<Card
					v-if="activeItem == 'id_card'"
					:register="true" />
				<XId
					v-if="activeItem == 'x_id'"
					:register="true" />
			</transition>
			<div
				class="other-methods"
				:class="{'mt-4': activeItem !== 'password'}">
				<span class="caption-1 caption-1-plus grayscale500--text">{{ $t("menu-items.other_methods") }}</span>
				<span
					v-for="loginMethod in otherLoginMethods"
					:key="loginMethod"
					class="caption-1 caption-1-plus primary-text-link cursor-pointer"
					@click="navigate(loginMethod)">
					{{ $t("menu-items." + loginMethod) }}
				</span>	
			</div>
			<div class="separator mt-4 mb-8" />
			<div	
				class="mt-4 text-center d-block d-sm-flex align-center justify-center">
				<span class="subhead-2 greybase-text mr-2">{{ $t('menu-items.already_have_an_account') }}</span>
				<router-link
					tag="span"
					class="body-3 body-3-plus primary-text-link cursor-pointer"
					:to="'/' + $i18n.locale">
					{{ $t('password_login.login') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

import Password from "@/components/Password.vue";
import Mobile from "@/components/Mobile.vue";
import Smart from "@/components/Smart.vue";
import Har from "@/components/Har.vue";
import Card from "@/components/Card.vue";
import XId from "@/components/XId.vue";
import ThirdParty from "@/components/ThirdParty.vue";

import Alert from "@/components/Alert.vue";
import { mapState, mapGetters } from 'vuex';
import { getEnv } from "../env";
export default {
	components: {
		Password,
		Mobile,
		Smart,
		Har,
		Card,
		XId,
		Alert,
		ThirdParty,
	},
	data () {
		return {
			activeItem: "password",
			primaryLoginMethods: getEnv('VUE_APP_FRONTEND_PRIMARY_LOGIN_METHODS').split(", "),
			secondaryLoginMethods: getEnv('VUE_APP_FRONTEND_SECONDARY_LOGIN_METHODS').split(", "),
			project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
			thirdPartyAllowedMethods: getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS') ? getEnv('VUE_APP_FRONTEND_THIRD_PARTY_ALLOWED_METHODS').split(", ") : null,
			thirdPartyAllowedForMobile: getEnv('VUE_APP_THIRD_PARTY_ALLOWED_FOR_MOBILE'),
		};
	},
	computed: {
		...mapState([
			'mobileView',
		]),
		...mapGetters([
			'getError',
			'getTeacherInviteDataSuccess',
			'getTeacherInviteDataErrors',
			'teacherInviteEmail',
		]),
		otherLoginMethods (){
			let allMethods = this.primaryLoginMethods.concat(this.secondaryLoginMethods);
			return allMethods.filter(e => e !== this.activeItem);
		},
	},
	mounted () {
		if(this.mobileView){
			//remove id-card login method in mobile view
			this.primaryLoginMethods = this.primaryLoginMethods.filter(e => e !== 'id_card' && e !== 'third_party')
		}	
		else{
			//if not mobile view, take login methods from env
			this.primaryLoginMethods = getEnv('VUE_APP_FRONTEND_PRIMARY_LOGIN_METHODS').split(", ")
		}
		if(this.$route.query.inviteType == 'staffRegistration' && this.$route.query.inviteHash){
			//send request to get teacher invite data
			let hash = this.$route.query.inviteHash;
			this.$store.dispatch('getTeacherInviteData', { hash }).then(
				() => {
					if(this.getTeacherInviteDataSuccess){
						//send to setupAccount view with teacher invite data as user param
						this.$router.push({
							name: "setupAccount",
							params: {
								user: { 'email': this.teacherInviteEmail },
								inviteType: this.$route.query.inviteType,
								inviteHash: this.$route.query.inviteHash,
							},
						});
					}
					else if(!this.getTeacherInviteDataSuccess && this.getTeacherInviteDataErrors !== ''){
						//show error
						this.$store.commit("changeError",
							("repeating_errors." + this.getTeacherInviteDataErrors));
						this.$store.commit("showError",true);
					}
				})
		}
	},
	methods: {
		navigate (item) {
			this.activeItem = item;
			this.$store.commit("showError", false);
		},
	},
};
</script>
