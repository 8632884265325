import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
import store from '..'
import { i18n } from "../../i18n";

Vue.use(Vuex)

export default{
	state: {
		mobileLoginBusy: false,
		mobileError: false,
		mobileLoggedIn: false,
		mobileLoginUrl: "",
		mobileShowRecaptcha: false,
		mobileSuccess: false,
		mobileErrors: "",
		mobileCodeSent: false,
		mobileCode: "8080",
		mobileAccounts: undefined,
		mobileUser: undefined,
		mobilePing: true,
		mobilePingBusy: false,
	},
	mutations: {
		loginMobileUser (state){
			state.mobileLoginBusy = true;
		},
		registerMobileUser (state){
			state.mobileLoginBusy = true;
		},
		resetMobileState (state){
			state.mobileLoginBusy = false;
			state.mobileError = false;
			state.mobileLoggedIn = false;
			state.mobileLoginUrl = "";
			state.mobileShowRecaptcha = false;
			state.mobileSuccess = false;
			state.mobileErrors = "";
			state.mobileCodeSent = false;
			state.mobileCode = "";
			state.mobileAccounts = undefined;
			state.mobileUser = undefined;
			state.mobilePing = true;
		},
		loginMobileUserSuccess (state,response){
			state.mobileLoginBusy = false;
			state.mobileSuccess = response.body.success;
			state.mobilePing = true;
			state.mobileShowRecaptcha = response.body.show_captcha;

			if(response.body.success){
				state.mobileCode = response.body.verification_code;
				state.mobileCodeSent = true;
			}
		},
		registerMobileUserSuccess (state,response){
			state.mobileLoginBusy = false;
			state.mobileSuccess = response.body.success;
			state.mobilePing = true;
			state.mobileShowRecaptcha = response.body.show_captcha;
			if(response.body.success){
				state.mobileCode = response.body.verification_code;
				state.mobileCodeSent = true;
				
			}
		},
		loginMobileUserFail (state, error){
			state.mobileLoginBusy = false;
			state.mobileError = true;
			state.mobileShowRecaptcha = error.body.show_captcha;
			state.mobileErrors = error.body.errors;
			
		},
		registerMobileUserFail (state, error){
			state.mobileLoginBusy = false;
			state.mobileLoginBusy = false;
			state.mobileError = true;
			state.mobileShowRecaptcha = error.body.show_captcha;
			state.mobileErrors = error.body.errors;
		},
		pingMobile (state){
			state.mobilePingBusy = true;
		},
		pingMobileSuccess (state,response){
			state.mobilePingBusy = false;
			state.mobileSuccess = response.body.success;
			if(state.mobileSuccess){
				state.mobilePing = false;
				if(response.body.login_method == 'mobile_id' && response.body.url){
					state.mobileLoggedIn = true;
					state.mobileLoginUrl = response.body.url;
				}
			}
			state.mobileLoginBusy = false;
			state.mobileAccounts = response.body.accounts;
			state.mobileUser = response.body.user;
			if(response.body.errors){
				state.mobileError = true;
				state.mobileErrors = response.body.errors;
				store.commit("changeError",
					i18n.t(state.mobileErrors));
				store.commit("showError");
				state.mobileCodeSent = false;
			}
		
		},
		pingMobileFail (state,error){
			state.mobilePingBusy = false;
			state.mobilePing = false;
			state.mobileLoginBusy = false;
			state.mobileShowRecaptcha = error.body.show_captcha;
			state.mobileCodeSent = false;
			state.mobileError = true;
			state.mobileErrors = error.body.errors;
			//state.mobileCodeSent = false;
		},
		cancelMobilePing (state){
			state.mobileCodeSent = false;
			state.mobilePing = false;
		},
	},
	actions: {
		loginCardMobile: (store, { personalCode, phoneNumber, recaptcha }) => {
			store.commit('loginMobileUser')
			return api.loginCardMobile( personalCode, phoneNumber, recaptcha)
				.then(
					response => {
						store.commit('loginMobileUserSuccess',response)
						return response;
					},
					error => {
						store.commit('loginMobileUserFail', error)
						return error;
					},
				)
		},
		registerCardMobile: (store, { personalCode, phoneNumber, recaptcha }) => {
			store.commit('registerMobileUser')
			return api.registerCardMobile( personalCode, phoneNumber, recaptcha)
				.then(
					response => {
						store.commit('registerMobileUserSuccess',response)
						return response;
					},
					error => {
						store.commit('registerMobileUserFail', error)
						return error;
					},
				)
		},
		loginPingMobile : (store) => {
			store.commit('pingMobile')
			return api.loginCardMobileCheck()
				.then(
					response => {
						store.commit('pingMobileSuccess',response)
						return response;
					},
					error => {
						store.commit('pingMobileFail',error)
						return error;
					},
				)
		},
		registerPingMobile : (store) => {
			store.commit('pingMobile')
			return api.registerCardMobileCheck()
				.then(
					response => {
						store.commit('pingMobileSuccess',response)
						return response;
					},
					error => {
						store.commit('pingMobileFail',error)
						return error;
					},
				)
		},
	},
	getters: {
		mobileLoginBusy: state => state.mobileLoginBusy,
		mobileError: state => state.mobileError,
		mobileErrors: state => state.mobileErrors,
		mobileLoggedIn: state => state.mobileLoggedIn,
		mobileLoginUrl: state => state.mobileLoginUrl,
		mobileShowRecaptcha: state => state.mobileShowRecaptcha,
		mobileShowTwoFactor: state => state.mobileShowTwoFactor,
		mobileCodeSent: state => state.mobileCodeSent,
		mobileCode: state => state.mobileCode,
		mobileAccounts: state => state.mobileAccounts,
		mobileUser: state => state.mobileUser,
		mobilePing: state => state.mobilePing,
		mobilePingBusy: state => state.mobilePingBusy,
		mobileSuccess: state => state.mobileSuccess,
	},
}