<template>
	<div class="view-content__wrapper">
		<div class="view-content d-block d-sm-flex">
			<!-- <ErrorMessage
				v-if="thirdPartyError"
				:errorMessage="thirdPartyError"> -->
			<transition name="fade">
				<div
					v-if="!thirdPartyBusy && !thirdPartyRequestBusy"
					class="permission-view mx-auto">
					<h1
						class="headline-1 graybase--text mb-8"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("permissions.wants_access_to", {X: permissionData.client_name}) }} 
					</h1>
					<div class="permission-list">
						<div
							v-for="(permission, index) in permissionDataEdited.scopes"
							v-if="index < itemLimit"
							:key="index"
							class="permission-list__entry">
							<div
								class="permission-list__entry__icon"
								:class="$vuetify.theme.dark ? 'grayscale600' : 'grayscale100'">
								<span
									class="primary--text"
									:class="permission.icon" />
							</div>
							<div
								class="permission-list__entry__text body-2"
								:class="$vuetify.theme.dark ? 'grayscale600--text' : 'graybase--text'">
								{{ $t(permission.description) }} 
							</div>
						</div>
						<div
							v-if="itemLimit == 4 && permissionDataEdited.scopes.length > 4"
							class="permission-list__entry">
							<div
								class="permission-list__entry__icon"
								:class="$vuetify.theme.dark ? 'grayscale600' : 'grayscale100'">
								<span class="primary--text icon-dots-horizontal" />
							</div>
							<div class="permission-list__entry__text">
								<e-button
									variant="link"
									small=""
									@click="showAllPermissions()">
									{{ $t('View more') }}
								</e-button>
							</div>
						</div>
					</div>

					<!-- If you change any of this, youre on your own, dont @ me on slack -->
					<div class="permission-footer mt-6">
						<form
							class="w-100"
							method="post"
							action="/oauth/authorize">
							<input
								type="hidden"
								name="_method"
								value="DELETE">
							<input
								type="hidden"
								name="state"
								:value="permissionData.state">
							<input
								type="hidden"
								name="client_id"
								:value="permissionData.client_id">
							<e-button
								:block="false"
								type="submit"
								variant="tertiary"
								size="large"
								large
								@click="denyThirdPartyRequestMethod">
								{{ $t('permissions.reject') }}
							</e-button>
						</form>
						<form
							class="w-100 ml-4"
							method="post"
							action="/oauth/authorize">
							<input
								type="hidden"
								name="state"
								:value="permissionData.state">
							<input
								type="hidden"
								name="client_id"
								:value="permissionData.client_id">
							<e-button
								:block="false"
								type="submit"
								variant="primary"
								size="large"
								large
								@click="acceptThirdPartyRequestMethod">
								{{ $t('permissions.allow') }}
							</e-button>
						</form>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<e-circular-loading
					v-if="thirdPartyBusy || thirdPartyRequestBusy"
					class="pt-4"
					value
					:fullPage="false"
					:size="42" />
			</transition>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
	data () {
		return{
			itemLimit : 4,
		}
	},
	computed:{
		...mapState('thirdPartyPermissions',
			[
				'thirdPartyBusy',
				'permissionData',
				'thirdPartyRequestBusy',
			],
		),
		...mapGetters('thirdPartyPermissions',[
			"permissionDataEdited",
		]),
	},
	mounted () {
		this.getThirdPartyData()
	},
	methods: {
		...mapActions('thirdPartyPermissions', 
			[ 
				'getThirdPartyData', 
				'acceptThirdPartyRequest', 
				'denyThirdPartyRequest', 
			]),
		acceptThirdPartyRequestMethod () {
			this.acceptThirdPartyRequest().then(() => {}, () => {})
		},
		denyThirdPartyRequestMethod () {
			this.denyThirdPartyRequest().then(() => {}, () => {})
		},
		showAllPermissions () {
			this.itemLimit = 1000;
		},
	},
};
</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
