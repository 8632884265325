<template>
	<form @submit.prevent="login()">
		<div class="mb-0">
			<div class="text-center py-4 px-10 headline-4">
				{{ $t("id_card_login.id_card_text") }}
			</div>
			<e-button
				class="mt-4"
				:block="true"
				type="submit"
				:loading="cardBusy"
				variant="primary"
				size="large"
				large>
				{{ $t('password_login.login') }}
			</e-button>
		</div>
	</form>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
	props: {
		register: Boolean,
	},
	computed: {
		...mapGetters([
			"cardBusy",
			"cardError",
			"cardSuccess",
			"cardErrors",
			"cardMultipleAccounts",
			"cardAccounts",
			"cardUser",
		]),
		...mapState ([
			'miniView',
		]),
	},
	methods: {
		login () {
			if (this.register == false) {
				this.loginId();
			} else if (this.register == true) {
				this.registerId();
			} else {
				this.$store.commit(
					"changeError",
					"id_card_login.submit_error",
				);
				this.$store.commit("showError", true);
			}
		},
		loginId () {
			this.$store.dispatch('cardLogin').then(	
				response => {
					if(this.cardSuccess && response.body.login_method == "id_card"){
						if (this.miniView) {
							this.postLoginMessage('success')
							return
						}
						window.top.location.href = response.body.url;
					}else if(this.cardSuccess && this.cardMultipleAccounts){
						this.$router.push({
							name: "chooseUser",
							params: {
								users: this.cardAccounts,
							},
						});
					}else if(this.cardError){
						this.$store.commit(
							"changeError",
							"id_card_login.submit_error",
						);
						this.$store.commit("showError", true);
					}
				},

			)
		},
		registerId () {
			this.$store.dispatch('cardRegister').then(	
				() => {
					if(this.cardSuccess && this.cardMultipleAccounts){
						this.$router.push({
							name: "chooseUser",
							params: {
								users: this.cardAccounts,
							},
						});
					}
					else if(this.cardUser !== undefined){
						this.$router.push({
							name: "setupAccount",
							params: {
								user: this.cardUser,
							},
						});
					}
					else if(this.cardErrors == 'user_id_code_already_exist'){
						this.$store.commit(
							"changeError",
							("repeating_errors." + this.cardErrors),
						);
						this.$store.commit("showError", true);
					}
					else if(this.cardError && this.cardErrors !== 'user_id_code_already_exist'){
						this.$store.commit(
							"changeError",
							"id_card_login.submit_error",
						);
						this.$store.commit("showError", true);
					}
				},

			)
		},
	},
};
</script>
