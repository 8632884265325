import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		forgotBusy: false,
		forgotError: false,
		forgotSuccess: false,
		forgotErrors: undefined,
		forgotShowRecaptcha: false,
	},
	mutations: {
		forgotPassword (state){
			state.forgotBusy = true;
		},
		forgotPasswordSuccess (state,response){
			state.forgotBusy = false;
			state.forgotSuccess = response.body.success;
			state.forgotShowRecaptcha = response.body.show_captcha;
		},
		forgotPasswordFail (state,error){
			state.forgotBusy = false;
			state.forgotError = true;
			state.forgotErrors = error.body.errors;
			state.forgotShowRecaptcha = error.body.show_captcha;
		},
		resetForgotState (state){
			state.forgotBusy = false;
			state.forgotError = false;
			state.forgotSuccess = false;
			state.forgotErrors = undefined;
			state.forgotShowRecaptcha = false;
		},
	},
	actions: {
		forgotPassword: (store, { username, recaptcha, token }) => {
			store.commit('forgotPassword')
			return api.forgotRequest(username, recaptcha, token)
				.then(
					response => {
						store.commit('forgotPasswordSuccess',response)
						return response;
					},
					error => {
						store.commit('forgotPasswordFail', error)
						return error;
					},
				)
		},
	},
	getters: {
		forgotBusy: state => state.forgotBusy,
		forgotError: state => state.forgotError,
		forgotSuccess: state => state.forgotSuccess,
		forgotErrors: state => state.forgotErrors,
		forgotShowRecaptcha: state => state.forgotShowRecaptcha,
	},
}