<template>
	<div class="d-block d-md-flex align-center justify-center mx-auto">
		<e-circular-loading
			v-if="twoFactorEmailVerifyBusy"
			value
			:timeout="400"
			:fullPage="false"
			:size="42" />
		<div
			v-else
			class="d-flex flex-column justify-center align-center">
			<div
				class="logo-container mb-8">
				<img
					src="../assets/ekool-logo.svg"
					alt="eKool logo">
				<img
					v-if="twoFactorEmailVerifySuccess"
					class="logo-container__protected-logo"
					:src="$vuetify.theme.dark ? require(`../assets/door-left-logo-dark.svg`) : require(`../assets/door-left-logo.svg`)"
					alt="door logo">
				<img
					v-else
					class="logo-container__protected-logo"
					:src="$vuetify.theme.dark ? require(`../assets/danger-logo-dark.svg`) : require(`../assets/danger-logo.svg`)"
					alt="door logo">
			</div>
			<div style="width: 100%; max-width: 500px;">
				<div
					v-if="twoFactorEmailVerifySuccess"
					class="headline-1 text-center">
					{{ $t('two_factor.email_link_success') }}
				</div>
				<div
					v-else
					class="headline-1 text-center">
					{{ $t('two_factor.email_link_error') }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data () {
		return {
			loading: false,
		};
	},
	computed: {
		...mapGetters([
			'twoFactorEmailVerifyBusy',
			'twoFactorEmailVerifySuccess',
			'twoFactorEmailVerifyErrors',
		]),
	},
	mounted (){
		this.twoFactorEmailVerify();
	},
	beforeDestroy (){
		this.$store.commit("showIlustration",true);
		this.$store.commit("showMobileAppPromo",true);
	},
	methods: {
		twoFactorEmailVerify (){
			let hash = this.$route.query.hash;
			this.$store.dispatch("twoFactorEmailVerify", { hash });
		},
	},
}
</script>