import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		getRolesBusy: false,
		getRolesError: false,
		getRolesSuccess: false,
		getRolesErrors: undefined,
		rolesData: [],
	},
	mutations: {
		//get role list
		getRoles (state){
			state.getRolesBusy = true;
		},
		getRolesSuccess (state,response){
			state.getRolesBusy = false;
			if (response.body.success) {
				state.getRolesSuccess = true;
				state.rolesData = response.data.data;
			}
		},
		getRolesFail (state,error){
			state.getRolesBusy = false;
			if(error.body.error){
				state.getRolesErrors = error.body.error;
				state.getRolesError = true;
			}
			else{
				state.getRolesError = true;
			}
		},
	},
	actions: {
		getRoles: (store) => {
			store.commit('getRoles')
			return api.getRoles()
				.then(
					response => {
						store.commit('getRolesSuccess',response)
						return response;
					},
					error => {
						store.commit('getRolesFail', error)
						return error;
					},
				)
		},
	},
	getters: {
		getRolesBusy: state => state.getRolesBusy,
		getRolesError: state => state.getRolesError,
		getRolesSuccess: state => state.getRolesSuccess,
		getRolesErrors: state => state.getRolesErrors,
		rolesData: state => state.rolesData,
	},
}