<template>
	<div class="view-content__wrapper">
		<div
			v-if="status == 'validating'"
			class="d-block d-sm-flex align-center mx-auto">
			<div>
				<e-circular-loading
					value
					:fullPage="false"
					:size="42" />
				<p class="headline-4 mt-10 mb-6">
					{{ $t("password_recovery.validating_token") }}
				</p>
			</div>
		</div>
		<div
			v-if="status == 'valid'"
			class="view-content">
			<p class="headline-1 mb-6">
				{{ $t("password_recovery.heading") }}
			</p>
			<Alert v-if="getError" />
			<ValidationObserver
				v-slot="{handleSubmit}">
				<form @submit.prevent="(newPasswordBusy || changePasswordBusy || adminChangePasswordBusy) ? '' : handleSubmit(reset)">
					<ValidationProvider
						v-slot="{errors}"
						v-if="$route.name == 'changePassword'"
						name="oldPassword"
						rules="required"
						slim
						class="mt-6 mb-4">
						<div class="py-4">
							<e-text-field
								v-model="oldPassword"
								large=""
								:label="$i18n.t('setup_account.old_password')"
								:type="showPassword1 ? 'text' : 'password'"
								:appendIcon="showPassword1 ? 'icon-eye-cut' : 'icon-eye'"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]"
								@click:append="showPassword1 = !showPassword1" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						name="password"
						rules="required|passwordStrength|min:9"
						slim
						class="mb-4">
						<div class="pt-4">
							<e-text-field
								v-model="password"
								large=""
								:label="$i18n.t('setup_account.password')"
								:type="showPassword2 ? 'text' : 'password'"
								:appendIcon="showPassword2 ? 'icon-eye-cut' : 'icon-eye'"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]"
								@click:append="showPassword2 = !showPassword2" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						name="repeatPassword"
						rules="required|password:@password"
						slim
						class="mb-4">
						<div class="pt-4">
							<e-text-field
								v-model="repeatPassword"
								large=""
								:label="$i18n.t('setup_account.repeat_password')"
								:type="showPassword3 ? 'text' : 'password'"
								:appendIcon="showPassword3 ? 'icon-eye-cut' : 'icon-eye'"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]"
								@click:append="showPassword3 = !showPassword3" />
						</div>
					</ValidationProvider>
					<e-button
						class="mt-6 callout-3"
						:block="true"
						type="submit"
						:loading="newPasswordBusy || changePasswordBusy || adminChangePasswordBusy"
						variant="primary"
						size="large"
						large>
						{{ $t('password_recovery.help_me') }}
					</e-button>
				</form>
			</ValidationObserver>
			<div class="text-center mt-4">
				<e-button
					variant="link"
					small=""
					:block="false"
					:href="homeUrl">
					{{ $t('setup_account.back_to_frontpage') }}
				</e-button>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@/components/Alert.vue";
import { mapGetters } from "vuex"
import { getEnv } from '../env';
export default {
	components: {
		Alert,
	},
	data () {
		return {
			status: "validating",
			username: "",
			oldPassword: "",
			password: "",
			repeatPassword: "",
			type: undefined,
			token: "",
			showPassword1: false,
			showPassword2: false,
			showPassword3: false,
			homeUrl: getEnv('VUE_APP_FRONTEND_APP_URL')+'/#/'+this.$i18n.locale,
		};
	},
	computed: {
		...mapGetters([
			"newPasswordBusy",
			"newPasswordError",
			"newPasswordSuccess",
			"newPasswordErrors",
			"newPasswordValidToken",
			"newPasswordValidationBusy",
			"getError",
			"changePasswordBusy",
			"changePasswordError",
			"changePasswordSuccess",
			"changePasswordErrors",
			"adminChangePasswordBusy",
			"adminChangePasswordError",
			"adminChangePasswordSuccess",
			"adminChangePasswordErrors",

		]),
	},
	mounted (){
		if(this.$route.params.token){
			this.token = this.$route.params.token;
		}
		if(this.$route.name == 'adminChangePassword'){
			this.type = 'admin_change'
		}
		if(this.$route.name == 'changePassword'){
			this.type = 'change'
		}
		if(this.$route.name == 'setNewPassword'){
			this.type = 'reset'
		}
		this.validate();


	},
	methods: {
		validate (){
			var token = this.token;
			var type = this.type;
			this.$store.dispatch('validateToken', { token, type }).then(
				() => {
					if(this.newPasswordValidToken){
						this.status = "valid";

					}

					else if(!this.newPasswordValidToken){
						this.$router.replace({
							name: "login",
							params: {
								error: 'token_validation_failed',
							},
						});
					}


				})
		},
		reset () {
			var token = this.token;
			var username = this.username;
			var oldPassword = this.oldPassword;
			var password = this.password;
			var passwordConfirmation = this.repeatPassword;
			if(this.$route.name == 'setNewPassword'){
				this.$store.dispatch('setNewPassword', { token,username,password,passwordConfirmation }).then(
					() => {
						if(this.newPasswordSuccess){
							this.$router.push({
								name: "success",
								params: {
									email: this.username,
									type: "reset",
								},
							});
						}else if(this.newPasswordError){
							if(this.$te("password_recovery." + this.newPasswordErrors)){
								this.$store.commit("changeError",
									("password_recovery." + this.newPasswordErrors));
								this.$store.commit("showError",true);
							}
							else{
								this.$store.commit("changeError",
									("repeating_errors." + this.newPasswordErrors));
								this.$store.commit("showError",true);
							}

						}

					})
			}
			else if(this.$route.name == 'changePassword'){
				this.$store.dispatch('changePassword', { token,username,oldPassword,password,passwordConfirmation }).then(
					() => {
						if(this.changePasswordSuccess){
							this.$router.push({
								name: "success",
								params: {
									email: this.username,
									type: "mykoobReset",
								},
							});
						}else if(this.changePasswordError){
							if(this.$te("password_recovery." + this.changePasswordErrors)){
								this.$store.commit("changeError",
									("password_recovery." + this.changePasswordErrors));
								this.$store.commit("showError",true);
							}
							else{
								this.$store.commit("changeError",
									("repeating_errors." + this.changePasswordErrors));
								this.$store.commit("showError",true);
							}

						}

					})
			}
			else if(this.$route.name == 'adminChangePassword'){
				this.$store.dispatch('adminChangePassword', { token,username,password,passwordConfirmation }).then(
					() => {
						if(this.adminChangePasswordSuccess){
							this.$router.push({
								name: "success",
								params: {
									email: this.username,
									type: "mykoobAdminReset",
								},
							});
						}else if(this.adminChangePasswordError){
							if(this.$te("password_recovery." + this.adminChangePasswordErrors)){
								this.$store.commit("changeError",
									("password_recovery." + this.adminChangePasswordErrors));
								this.$store.commit("showError",true);
							}
							else{
								this.$store.commit("changeError",
									("repeating_errors." + this.adminChangePasswordErrors));
								this.$store.commit("showError",true);
							}

						}

					})
			}

		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
