<template>
	<div class="view-content__wrapper">
		<div class="view-content">
			<div class="d-flex justify-space-between align-center mb-6">
				<div class="headline-1">
					{{ $t("school_registration." + currentStep.name) }}
				</div>
				<div>
					<e-stepper-indicator
						:value="currentStep.order"
						:steps="registrationSteps.length">
						<span :class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">{{ currentStep.order }} / {{ registrationSteps.length }}</span>
					</e-stepper-indicator>
				</div>
			</div>
			<ValidationObserver
				v-slot="{handleSubmit}">
				<form
					v-if="currentStep.order == 1"
					@submit.prevent="handleSubmit(forward)">
					<ValidationProvider
						v-slot="{errors}"
						name="schoolName"
						rules="required"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="schoolName"
								large=""
								:label="$i18n.t('school_registration.schools_name')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						name="registrationNumber"
						rules="required"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="registrationNumber"
								large=""
								:label="$i18n.t('school_registration.registration_number')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						name="schoolType"
						rules="required"
						slim>
						<div class="py-4">
							<e-select
								v-model="schoolType"
								large=""
								class="body-2"
								:error="errors[0] && errors[0] !== ''"
								:errorMessages="errors[0]"
								:label="$i18n.t('school_registration.schools_type')"
								:items="schoolTypes"
								:placeholder="$i18n.t('setup_account.select')"
								item-text="name"
								item-value="value" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						rules="required"
						name="street"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="street"
								large=""
								:label="$i18n.t('setup_account.street')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						rules="required"
						name="city"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="city"
								large=""
								:label="$i18n.t('setup_account.city')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						rules="required"
						name="country">
						<div class="py-4">
							<e-select
								v-model="country"
								large=""
								class="body-2"
								:error="errors[0] && errors[0] !== ''"
								:errorMessages="errors[0]"
								:label="$i18n.t('setup_account.country')"
								:items="countries"
								:placeholder="$i18n.t('setup_account.select')"
								item-text="Name"
								item-value="Code" />
						</div>
					</ValidationProvider>
					<e-button
						class="mt-4"
						:block="true"
						type="submit"
						variant="primary"
						size="large"
						large>
						{{ $t('setup_account.forward') }}
					</e-button>
					<div class="mt-4 text-center">
						<e-button
							variant="link"
							small=""
							:block="false"
							:to="'/' + $i18n.locale">
							{{ $t("forgot_password.back_to_frontpage") }}
						</e-button>
					</div>
				</form>
			</ValidationObserver>
			<ValidationObserver
				v-slot="{handleSubmit}">
				<form
					v-if="currentStep.order == 2"
					class=""
					@submit.prevent="schoolRegisterBusy ? '' : handleSubmit(checkPersonal)">
					<ValidationProvider
						v-slot="{errors}"
						rules="required"
						name="firstName"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="firstName"
								large=""
								:label="$i18n.t('setup_account.first_name')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						rules="required"
						name="lastName"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="lastName"
								large=""
								:label="$i18n.t('setup_account.last_name')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						rules="required|email"
						name="email"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="email"
								large=""
								:label="$i18n.t('setup_account.email')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<ValidationProvider
						v-slot="{errors}"
						rules="required"
						name="phone"
						slim>
						<div class="py-4">
							<e-text-field
								v-model="number"
								large=""
								:label="$i18n.t('mobile_id_login.phone_number')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<div class="mt-2 d-flex justify-space-between align-center">
						<div>
							<e-button
								variant="link"
								small=""
								:block="false"
								@click="back()">
								{{ $t('setup_account.go_back') }}
							</e-button>
						</div>
							
						<e-button
							:block="false"
							type="submit"
							:loading="schoolRegisterBusy"
							variant="primary"
							size="large"
							large>
							{{ $t('school_registration.register_school') }}
						</e-button>
					</div>
					<p class="body-3 text-center mt-4">
						<span class="grayscale400--text">{{ $t("school_registration.school_terms_text") }} </span>
						<a
							class="text-decoration-none primary--text"
							:href="$i18n.locale == 'et' ? 'https://www.ekool.eu/teenusetingimused' : 'https://www.ekool.eu/en/terms-of-service'"
							target="_blank">{{ $t("school_registration.school_terms_link_1") }} </a>
						<span class="grayscale400--text">{{ $t("school_registration.and") }} </span>
						<a
							class="text-decoration-none primary--text"
							:href="
								'https://ekool.eu/terms/data_processing_contract_' +
									$i18n.locale +
									'.html'
							"
							target="_blank">{{ $t("school_registration.school_terms_link_2") }}</a>
					</p>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { countries } from "../utils/countries";
export default {
	name: "SchoolRegister",
	data () {
		return {
			spinner: false,
			activeItem: "school",
			schoolName: "",
			registrationNumber: "",
			schoolType: "",
			webpage: "",
			street: "",
			city: "",
			index: "",
			county: "",
			country: undefined,
			countries: countries,
			firstName: "",
			lastName: "",
			email: "",
			number: "",
			currentStep: {},
			registrationSteps: [
				{ order: 1, name: "schools_data" },
				{ order: 2, name: "personal_data" },
			],
		};
	},
	computed: {
		...mapGetters([
			'schoolRegisterBusy',
			'schoolRegisterError',
			'schoolRegisterSuccess',
			'schoolRegisterErrors',
			'getError',
		]),
		schoolTypes (){
			return [
				{ value : 1, name: this.$i18n.t('school_registration.elementary') },
				{ value : 2, name: this.$i18n.t('school_registration.high_school') },
				{ value : 3, name: this.$i18n.t('school_registration.primary_school') },
				{ value : 8, name: this.$i18n.t('school_registration.vocational') },
				{ value : 9, name: this.$i18n.t('school_registration.hobby_school') },
				{ value : 10, name: this.$i18n.t('school_registration.local_government') },
				{ value : 18, name: this.$i18n.t('school_registration.publisher') },
				{ value : 19, name: this.$i18n.t('school_registration.advertising_agency') },
				{ value : 20, name: this.$i18n.t('school_registration.user_group') },
			]
		},
	},
	mounted (){
		this.currentStep = this.registrationSteps[0];
	},
	methods: {
		forward () {
			this.currentStep = this.registrationSteps.find(el => el.order == this.currentStep.order + 1);
		},
		back () {
			this.currentStep = this.registrationSteps.find(el => el.order == this.currentStep.order - 1);
		},
		checkPersonal () {
			var schoolName = this.schoolName;
			var registrationNumber = this.registrationNumber;
			var type = this.schoolType;
			var street = this.street;
			var city = this.city;
			var country = this.country;
			var firstName = this.firstName;
			var lastName = this.lastName;
			var email = this.email;
			var number = this.number;
			this.$store.dispatch('schoolRegister', { schoolName,registrationNumber,type,street,city,country,firstName,lastName,email,number }).then(
				() => {
					if(this.schoolRegisterError){
						this.$router.push({
							name: "failed",
							params: {
								type: "schoolRegister",
							},
						});
					}
					else if(this.schoolRegisterError && this.schoolRegisterErrors !== undefined){
						this.$store.commit("changeError",
							("school_registration." + this.schoolRegisterErrors));
						this.$store.commit("showError",true);
					}
					else if(this.schoolRegisterSuccess){
						this.$router.push({
							name: "success",
							params: {
								type: "school",
							},
						});
					}
				},	
			)
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
