import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		getRedirectBusy: false,
		getRedirectSuccess: false,
		redirectUrl: undefined,
	},
	mutations: {
		//get redirect
		getRedirect (state){
			state.getRedirectBusy = true;
		},
		getRedirectSuccess (state,response){
			state.getRedirectBusy = false;
			state.getRedirectSuccess = response.body.success;
			state.redirectUrl = response.data.url;
		},
		getRedirectFail (state){
			state.getRedirectBusy = false;
			state.redirectUrl = undefined;
		},
	},
	actions: {
		getRedirect: (store, { target }) => {
			store.commit('getRedirect')
			return api.getRedirect(target)
				.then(
					response => {
						store.commit('getRedirectSuccess',response)
						return response;
					},
					error => {
						store.commit('getRedirectFail', error)
						return error;
					},
				)
		},
	},
	getters: {
		getRedirectBusy: state => state.getRedirectBusy,
		getRedirectSuccess: state => state.getRedirectSuccess,
		redirectUrl: state => state.redirectUrl,
	},
}