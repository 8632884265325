<template>
	<div class="view-content__wrapper">
		<div class="view-content d-block d-md-flex align-center justify-center mx-auto">
			<div
				v-if="type == 'registrationHash'"
				class="registration">
				<div class="text-center mb-8">
					<v-icon size="70">
						icon-exclamation warning--text
					</v-icon>
				</div>
				<p
					class="headline-4 text-center mx-10 mb-3"
					:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
					{{ $t('registration_message.user_already_exists') }}
				</p>
				<p
					class="body-3 text-center mb-6"
					:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
					{{ $t('registration_message.connect_with_user') }}
				</p>
				<div class="text-center">
					<e-button
						variant="link"
						small=""
						:block="false"
						class="mb-4"
						@click="proceedRegistration()">
						{{ $t('registration_message.register_new_user') }}
					</e-button>
				</div>
				<e-button
					class="callout-3"
					:block="true"
					:to="'/' + $i18n.locale"
					variant="primary"
					size="large"
					large>
					{{ $t("registration_message.login_existing_user") }}
				</e-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data () {
		return {
			type: undefined,
			hash: undefined,
			message: "",
			thirdParty: false,
		};
	},
	computed: {
		...mapGetters([
			'proceedRegistrationErrors',
			'proceedRegistrationSuccess',
			'setupAccountHash',
		]),
	},
	mounted () {
		if(this.$route.params.type){
			this.type = this.$route.params.type;
		}

		else if(this.$route.query.type){
			this.type = this.$route.query.type;
		}
		
		if(this.type == undefined){
			this.$router.replace({
				name: "login",
			});
		}

		if(this.$route.params.thirdParty){
			this.thirdParty = this.$route.params.thirdParty;
		}
	},
	methods: {
		proceedRegistration () {
			var hash = this.setupAccountHash;
			this.$store.dispatch('proceedRegistration', { hash }).then(
				() => {
					if(this.proceedRegistrationSuccess){
						if(this.thirdParty){
							this.$router.push({
								name: "welcome",
							});
						}
						else{
							this.$router.push({
								name: "success",
								params: {
									email: this.email,
									type: "registration",
								},
							});
						}
					}else if(!this.proceedRegistrationSuccess && this.proceedRegistrationErrors){
						this.$router.replace({
							name: "login",
							params: {
								error: 'repeating_errors.' + this.proceedRegistrationErrors,
							},
						});
					}
				},	

			)
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
