import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		changePasswordBusy: false,
		changePasswordError: false,
		changePasswordSuccess: false,
		changePasswordErrors: undefined,
	},
	mutations: {
		//token validation to be added..
        
		//password reset
		changePassword (state){
			state.changePasswordBusy = true;
		},
		changePasswordSuccess (state,response){
			state.changePasswordBusy = false;
			if (response.body.success) {
				state.changePasswordSuccess = true;
			}
		},
		changePasswordFail (state,error){
			state.changePasswordBusy = false;
			if(error.body.errors){
				state.changePasswordErrors = error.body.errors;
				state.changePasswordError = true;
			}
			else{
				state.changePasswordError = true;
			}
		},
		
	},
	actions: {
		changePassword: (store, { token,username,oldPassword,password,passwordConfirmation }) => {
			store.commit('changePassword')
			return api.changePassword(token,username,oldPassword,password,passwordConfirmation)
				.then(
					response => {
						store.commit('changePasswordSuccess',response)
						return response;
					},
					error => {
						store.commit('changePasswordFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		changePasswordBusy: state => state.changePasswordBusy,
		changePasswordError: state => state.changePasswordError,
		changePasswordSuccess: state => state.changePasswordSuccess,
		changePasswordErrors: state => state.changePasswordErrors,
	},
}