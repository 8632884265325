<template>
	<div class="view-content__wrapper">
		<div class="view-content">
			<h2 class="headline-1 mb-2">
				{{ $t("forgot_password.heading") }}
			</h2>
			<h6 class="subhead-1 mb-6">
				{{ $t("forgot_password.intro") }}
			</h6>
			<Alert v-if="getError" />
			<ValidationObserver
				v-slot="{handleSubmit}">
				<form @submit.prevent="forgotBusy ? '' : handleSubmit(forgot)">
					<ValidationProvider
						v-slot="{errors}"
						mode="passive"
						name="email"
						rules="required">
						<div class="py-4">
							<e-text-field
								v-model="username"
								large=""
								:type="usernameType"
								:label="$i18n.t('forgot_password.label')"
								:error="errors[0] && errors[0] !== ''"
								:error-messages="errors[0]" />
						</div>
					</ValidationProvider>
					<div class="d-block text-center mx-auto">
						<vue-recaptcha
							v-show="forgotShowRecaptcha"
							ref="recaptcha"
							class="recaptcha"
							:sitekey="recaptcha.siteKey"
							size="normal"
							:load-recaptcha-script="true"
							@verify="onVerify" />
					</div>
					<e-button
						class="mt-2"
						:block="true"
						type="submit"
						:loading="forgotBusy"
						:disabled="disableButton"
						variant="primary"
						size="large"
						large>
						{{ $t("forgot_password.help_me") }}
					</e-button>
				</form>
			</validationobserver>
			<div class="text-center mt-4">
				<e-button
					variant="link"
					small=""
					:block="false"
					@click="redirect()">
					{{ $t("setup_account.back_to_frontpage") }}
				</e-button>
			</div>
			<div
				v-show="showCFTurnstileWidget"
				class="turnstile-widget mt-4"
				:class="{'d-flex': showCFTurnstileWidget}" />
		</div>
	</div>
</template>

<script>
import Alert from "@/components/Alert.vue";
//import { forgotRequest, } from "../api";
import { mapGetters } from 'vuex';
import { getEnv } from '../env';
import VueRecaptcha from "vue-recaptcha";
export default {
	name: "Forgot",
	components: {
		Alert,
		VueRecaptcha,
	},
	data () {
		return {
			username: "",
			usernameType: "text",
			recaptcha: {
				recaptcha: "",
				siteKey: getEnv('VUE_APP_FRONTEND_RECAPTCHA_SITE_KEY'),
			},
			showCFTurnstileWidget: false,
			showCFTurnstileWidgetTimeout: null,
		};
	},
	computed: {
		locale (){
			return this.$i18n.locale;
		},
		...mapGetters([
			'forgotBusy',
			'forgotError',
			'forgotSuccess',
			'forgotErrors',
			'forgotShowRecaptcha',
			'getError',
			'CFTurnstileToken',
			'CFTurnstileWidgetId',
			'disableButton',
			'CFTurnstileScriptLoaded',
		]),
	},
	watch: {
		CFTurnstileScriptLoaded (val) {
			if(val){
				this.initCFTurnstile();
			}
		},
	},
	mounted () {
		if(getEnv('VUE_APP_FRONTEND_PROJECT_NAME') == 'Mykoob'){
			this.usernameType = 'email'
		}
		if(this.CFTurnstileScriptLoaded){
			this.initCFTurnstile();
		}
	},
	beforeDestroy () {
		/* eslint-disable no-undef */
		turnstile.remove(this.CFTurnstileWidgetId)
		if(this.showCFTurnstileWidgetTimeout){
			clearTimeout(this.showCFTurnstileWidgetTimeout);
			this.showCFTurnstileWidget = false;
		}
	},
	methods: {
		forgot () {
			var username = this.username;
			var recaptcha = this.recaptcha.recaptcha;
			var token = this.CFTurnstileToken;
			this.$store.commit("resetForgotState"); //reset state
			this.$store.dispatch('forgotPassword', { username, recaptcha, token }).then(
				() => {
					/* eslint-disable no-undef */
					turnstile.reset(this.CFTurnstileWidgetId)
					if(this.forgotShowRecaptcha){
						this.resetRecaptcha();
					}
					if(this.forgotError){
						this.$store.commit("setDisableButton",true);
						if(this.forgotErrors !== undefined){
							if(this.forgotErrors == 'recaptcha_required' || this.forgotErrors == 'recaptcha_failed'){
								this.$store.commit("changeError",
									("setup_account.recaptcha_failed"));
								this.$store.commit("showError",true);
							}
							else if(this.$te("forgot_password." + this.forgotErrors)){
								this.$store.commit("changeError",
									("forgot_password." + this.forgotErrors));
								this.$store.commit("showError",true);
							}
							else{
								this.$store.commit("changeError",
									("repeating_errors." + this.forgotErrors));
								this.$store.commit("showError",true);
							}
						}
						else{
							this.$store.commit("changeError",
								"forgot_password.user_not_found");
							this.$store.commit("showError",true);
						}
					}
					else if(this.forgotSuccess){
						this.$router.push({
							name: "success",
							params: {
								email: this.username,
								type: "resetRequest",
							},
						});
					}
				},
			)
		},
		onVerify (response) {
			this.recaptcha.recaptcha = response;
		},
		resetRecaptcha () {
			this.recaptcha.showRecaptcha = true;
			this.$refs.recaptcha.reset(); // Direct call reset method
		},
		redirect () {
			this.$store.commit("resetForgotState");
			this.$router.push('/' + this.$i18n.locale)
		},
		initCFTurnstile (){
			this.$store.commit("setDisableButton",true);
			/* eslint-disable no-undef */
			let widgetId = turnstile.render('.turnstile-widget', {
				sitekey: getEnv('VUE_APP_FRONTEND_CF_TURNSTILE_SITE_KEY'),
				theme: this.$vuetify.theme.dark ? 'dark' : 'light',
				callback: (token) => {
					this.$store.commit("setCFTurnstileToken",token);
					this.$store.commit("setDisableButton",false);
					this.showCFTurnstileWidget = false;
				},
				'error-callback': () => {
					if(this.mobileView){
						this.$store.commit("setDisableButton",false);
					}
					this.showCFTurnstileWidgetTimeout = setTimeout(() => this.showCFTurnstileWidget = true,5000);
				},
			});
			this.$store.commit("setCFTurnstileWidgetId",widgetId);
			//reset CF turnstile every 295 seconds
			setInterval(() => {
				turnstile.reset(this.CFTurnstileWidgetId);
			}, 295000)
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
