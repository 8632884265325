<template>
	<div class="terms-view">
		<e-dialog
			v-model="dialog5"
			:title="$t(termsModalHeading)"
			persistent
			max-width="785"
			hide-default-header>
			<template #content>
				<div class="d-flex flex-column overflow-hidden">
					<div class="px-4 pt-4">
						<div class="pb-4 d-flex flex-row terms-modal__header">
							<e-button
								v-if="currentTermsPage == 'privacyPolicy'"
								class="terms-modal__header__button"
								icon="icon-arrow-left"
								variant="ghost"
								@click="openServiceTerms" />
							<div class="text-center">
								<h1 class="headline-1">
									{{ $t(termsModalHeading) }}
								</h1>
							</div>
						</div>
					</div>
					
					<div
						style="overflow-y: scroll"
						class="terms-content">
						<!-- Service terms -->
						<div
							v-if="currentTermsPage == 'service'"
							@click="handleClick"
							v-html="serviceTerms" />
						<!-- Privacy policy terms -->
						<div
							v-if="currentTermsPage == 'privacyPolicy'"
							@click="handleClick"
							v-html="privacyPolicyTerms" />
					</div>
				</div>
			</template>
			<template #actions>
				<div class="terms-button-container">
					<e-button
						variant="ghost"
						@click="download">
						{{ $t('terms.save') }}
					</e-button>
					<div
						v-if="currentTermsPage == 'service'"
						class="terms-button-container__group">
						<e-button
							variant="tertiary"
							class="mr-3"
							@click="toLogin">
							{{ $t('terms.cancel') }}
						</e-button>
						<e-button
							variant="primary"
							:loading="acceptTermsBusy"
							@click="acceptTerms">
							{{ $t('terms.agree') }}
						</e-button>
					</div>
				</div>
			</template>
		</e-dialog>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getEnv } from "../env";
export default {
	data (){
		return{
			dialog5: true,
			currentTermsPage: "service",
			prevShowIlustration: false,
		}
	},
	computed:{
		...mapGetters([
			'serviceTermsBusy',
			'serviceTerms',
			'cookiesPrivacyPolicyTermsBusy',
			'privacyPolicyTerms',
			'privacyPolicyTermsBusy',
			'acceptTermsBusy',
			'acceptTermsError',
			'acceptTermsSuccess',
			'acceptTermsUrl',
			'showIlustration',
		]),
		termsModalHeading (){
			return this.currentTermsPage == 'service' ? 'terms.terms_of_service' : 'terms.privacy_policy'
		},
	},
	mounted (){
		this.prevShowIlustration = this.showIlustration

		this.$store.commit("showIlustration",false); //hide ilustration
		this.getServiceTerms();
		this.getPrivacyPolicyTerms();
	},
	beforeDestroy (){
		this.prevShowIlustration ? this.$store.commit("showIlustration", true) : null //show ilustration
	},
	methods: {
		toLogin () {
			location.href = getEnv('VUE_APP_FRONTEND_APP_URL') + '/logout'
		},
		getServiceTerms () {
			this.$store.dispatch('getServiceTerms')
		},
		getPrivacyPolicyTerms () {
			this.$store.dispatch('getPrivacyPolicyTerms')
		},
		openPrivacyPolicy () {
			this.currentTermsPage = "privacyPolicy"
		},
		handleClick (e) {
			let clickedElId = e.target.id
			if (clickedElId === 'privacyPolicy') {
				this.openPrivacyPolicy()
			}
		},
		acceptTerms (){
			let termsAccepted = true
			this.$store.dispatch('acceptTerms', { termsAccepted }).then(
				() => {
					if(this.acceptTermsSuccess && this.acceptTermsUrl !== ""){
						window.top.location.href = this.acceptTermsUrl;
					}
					if(!this.acceptTermsSuccess && this.acceptTermsError != ""){
						location.href = location.href = getEnv('VUE_APP_FRONTEND_APP_URL') + `/logout?error_code=${this.acceptTermsError}`
					}
				},
			)
		},
		download () {
			let fileLink = this.currentTermsPage == 'service' ? `${getEnv('VUE_APP_FRONTEND_APP_URL')}/terms/service-terms/download` : `${getEnv('VUE_APP_FRONTEND_APP_URL')}/terms/privacy-policy/download`
			window.open(fileLink, '_blank');
		},
		openServiceTerms () {
			this.currentTermsPage = "service"
		},
	},
	
}
</script>