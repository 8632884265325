import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import VueEkoolPlugin from '@k12tech/ekool-vue-framework';
import '@k12tech/ekool-vue-framework/src/scss/main.scss';

//gemius tracking
import { postLoginMessage } from './utils/globalMethods'
// import * as gemius from "@/gemius/gemius.js";
// if(getEnv('VUE_APP_FRONTEND_PROJECT_NAME') === 'Mykoob'){
// 	gemius.gemius();
// }

import { i18n } from "./i18n";
import "./vee-validate";
import vueHeadful from "vue-headful";
import VueCookies from 'vue-cookies';
import FlagIcon from "vue-flag-icon";
// import { getEnv } from "./env";



Vue.use(VueCookies);
Vue.use(FlagIcon);

Vue.mixin({
	methods: {
		postLoginMessage,
	},
})


Vue.component("VueHeadful", vueHeadful);
Vue.config.productionTip = false;

const context = {
	vuetify,
	router,
	store,
	i18n,
}

Vue.use(VueEkoolPlugin, { context })

new Vue({
	...context,
	VueCookies,
	render: (h) => h(App),
}).$mount("#app");
