<template>
	<div class="view-content__wrapper">
		<div
			v-if="getRolesBusy"
			class="d-flex justify-center align-cente">
			<e-circular-loading
				value
				:fullPage="false"
				:size="42" />
		</div>
		<div
			v-if="!getRolesBusy && rolesData">
			<div
				class="view-content">
				<div class="mb-6 headline-1">
					{{ $t("choose_user.choose_user") }}
				</div>
				<div class="role__container">
					<div
						v-for="(role, id) in rolesData"
						:key="id"
						class="role">
						<div>
							<div
								v-for="(roleValue, roleName, id) in role"
								v-if="roleName !== 'role'"
								:key="id + 'a'"
								class="role__data">
								<span
									class="body-3 role__data__name">
									{{ $t('roles.' + roleName) }}:
								</span>
								<span
									v-if="$te('roles.' + roleValue)"
									class="body-3 role__data__value">
									{{ $t('roles.' + roleValue) }}
								</span>
								<span
									v-else
									class="body-3 role__data__value">
									{{ roleValue }}
								</span>
							</div>
						</div>
						<e-button
							class="role__button"
							:block="false"
							:loading="selected == id"
							variant="secondary"
							@click="chooseRole(id)">
							{{ $t("choose_user.choose") }}
						</e-button>
					</div>
				</div>
				<div class="text-center mt-7">
					<e-button
						variant="link"
						small=""
						:block="false"
						:to="'/' + $i18n.locale">
						{{ $t("forgot_password.back_to_frontpage") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import { selectUser, } from "../api/index.js";
import { mapGetters } from "vuex";
import { getEnv } from "../env";
export default {
	data () {
		return {
			selected: -300,
		};
	},
	computed:{
		...mapGetters([
			'getRolesBusy',
			'getRolesError',
			'getRolesSuccess',
			'getRolesErrors',
			'getError',
			'rolesData',
		]),
	},
	mounted () {
		this.getRoleList();
	},
	methods: {
		getRoleList () {
			this.$store.dispatch('getRoles').then(
				() => {
					//if error
					if(this.getRolesError){
						this.$router.replace({
							name: "login",
							params: {
								error: this.getRolesErrors,
							},
						});
					}
				},	
			)
		},
		chooseRole (id) {
			this.selected = id;
			//redirect to third party
			window.location.href = getEnv('VUE_APP_FRONTEND_APP_URL') + `/oauth/select-role?role_key=${id}`
		},
	},
};
</script>