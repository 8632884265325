<template>
	<div class="mb-0">
		<div class="text-center py-4 px-10 headline-4">
			{{ $t("har_id_login.har_id_text") }}
		</div>
		<e-button
			class="mt-4"
			:block="true"
			:href="link"
			:target="iframeView ? '_parent' : '_self'"
			variant="primary"
			size="large"
			large>
			{{ $t('password_login.login') }}
		</e-button>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	props:{
		register: Boolean,
	},
	data () {
		return {
			link: "",
		};
	},
	computed: {
		...mapGetters([
			'iframeView',
		]),
	},
	mounted (){
		if(this.register){
			this.link = 'register/har-id';
		}else{
			this.link = '/har-id';
		}
		
		
	},
};
</script>
