import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default{
	state: {
		CFTurnstileToken: null,
		CFTurnstileWidgetId: null,
		disableButton: true,
		CFTurnstileScriptLoaded: false,
	},
	mutations: {
		setCFTurnstileToken (state, value) {
			state.CFTurnstileToken = value;
		},
		setCFTurnstileWidgetId (state, value) {
			state.CFTurnstileWidgetId = value;
		},
		setDisableButton (state, value) {
			state.disableButton = value;
		},
		setCFTurnstileScriptLoaded (state, value) {
			state.CFTurnstileScriptLoaded = value;
		},
	},
	getters: {
		CFTurnstileToken: state => state.CFTurnstileToken,
		CFTurnstileWidgetId: state => state.CFTurnstileWidgetId,
		disableButton: state => state.disableButton,
		CFTurnstileScriptLoaded: state => state.CFTurnstileScriptLoaded,
	},
}