import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		chooseUserBusy: false,
		chooseUserError: false,
		chooseUserSuccess: false,
		chooseUserErrors: undefined,
	},
	mutations: {
		//token validation to be added..
        
		//password reset
		chooseUser (state){
			state.chooseUserBusy = true;
		},
		chooseUserSuccess (state,response){
			state.chooseUserBusy = false;
			if (response.body.success) {
				state.chooseUserSuccess = true;
			}
		},
		chooseUserFail (state,error){
			state.chooseUserBusy = false;
			if(error.body.errors){
				state.chooseUserErrors = error.body.errors;
				state.chooseUserError = true;
			}
			else{
				state.chooseUserError = true;
			}
		},
		
	},
	actions: {
		chooseUser: (store, { id }) => {
			store.commit('chooseUser')
			return api.selectUser(id)
				.then(
					response => {
						store.commit('chooseUserSuccess',response)
						return response;
					},
					error => {
						store.commit('chooseUserFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		chooseUserBusy: state => state.chooseUserBusy,
		chooseUserError: state => state.chooseUserError,
		chooseUserSuccess: state => state.chooseUserSuccess,
		chooseUserErrors: state => state.chooseUserErrors,
	},
}