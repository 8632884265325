import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default{
	state: {
		showFooter: true,
		showNav: true,
		showIlustration: true,
		iframeView: false,
		showLanguages: true,
		mobileView: false,
		mobileAppPromo: true,
	},
	mutations: {
		showFooter (state,value){
			state.showFooter = value;
		},
		showNav (state,value){
			state.showNav = value;
		},
		showIlustration (state,value){
			state.showIlustration = value;
		},
		setIframeView (state, value){
			state.iframeView = value;
		},
		showLanguages (state, value){
			state.showLanguages = value;
		},
		setMobileView (state, value){
			state.mobileView = value;
		},
		showMobileAppPromo (state, value){
			state.mobileAppPromo = value;
		},
	},
	getters: {
		showFooter: state => state.showFooter,
		showNav: state => state.showNav,
		showIlustration: state => state.showIlustration,
		iframeView: state => state.iframeView,
		showLanguages: state => state.showLanguages,
		mobileView: state => state.mobileView,
		mobileAppPromo: state => state.mobileAppPromo,
	},
}