<template>
	<div>
		<div
			v-if="showGetApp && showMobileAppPromo"
			class="get-app d-flex d-sm-none">
			<div class="get-app__left">
				<e-button
					icon="icon-close-sign"
					variant="ghost"
					class="mx-2"
					@click="showGetApp = false" />
				<div class="get-app__left__logo__container">
					<img
						class="get-app__left__logo"
						:style="{'max-width': device == 'ios' ? '3rem' : '2.5rem'}"
						:src="imageSrc"
						alt="app icon">
				</div>
				<span class="body-3 ml-2">{{ $t('get_app.intro') }}</span>
			</div>
			<e-button
				variant="primary"
				:href="appLink"
				target="_blank">
				{{ $t('get_app.download') }}
			</e-button>
		</div>
		<nav class="navbar">
			<a
				v-if="project == 'eKool'"
				:href="logoLink">
				<e-logo small="" />
			</a>
			<a
				v-else
				class="navbar__brand"
				:href="logoLink">
				<img
					class="navbar__brand__img"
					:src="$vuetify.theme.dark ? require(`../assets/mykoob-logo-light.png`) : require(`../assets/mykoob-logo-dark.png`)"
					alt="mykoob logo">
			</a>
			<e-select
				v-if="showLanguages"
				v-model="selectedLang"
				class="navbar__lang-switch body-2 text-uppercase graybase--text"
				:prepend-inner-icon="$vuetify.theme.dark ? 'icon-globe grayscale100--text' : 'icon-globe graybase--text'"
				:appendIcon="$vuetify.theme.dark ? 'icon-chevron-down grayscale100--text' : 'icon-chevron-down graybase--text'"
				item-text="name"
				item-value="value"
				:items="localeObjectArray"
				@input="changeLocale(selectedLang)" />
		</nav>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getEnv } from '../env';
export default {
	data () {
		return {
			selectedLang: this.$i18n.locale,
			languageOptions: getEnv('VUE_APP_FRONTEND_LOCALES').split(", "),
			localeObjectArray: [],
			logoLink: "",
			project: getEnv('VUE_APP_FRONTEND_PROJECT_NAME'),
			showGetApp: false,
			appLink: "https://play.google.com/store/apps/details?id=eu.ekool.app&hl=en&gl=US",
			imageSrc: "",
			device: "",
		};
	},
	computed: {
		...mapGetters([
			'showLanguages',
			'showMobileAppPromo',
		]),
	},
	mounted () {
		//initial locale cookie
		this.$cookies.config('7d','','',false);
		this.$cookies.set('locale', this.$i18n.locale);
		this.setRedirectUrl();
		this.convertLocales();
		this.initGetApp();
	},
	methods: {
		changeLocale (locale) {
			this.selectedLang = locale;
			this.$i18n.locale = locale;
			this.$router.push({ params: { lang: locale } });
			this.$cookies.config('7d','','',false);
			this.$cookies.set('locale', locale);
			this.setRedirectUrl();
		},
		setRedirectUrl () {
			this.logoLink = getEnv('VUE_APP_FRONTEND_APP_URL') +'/#/' + this.$i18n.locale;
		},
		convertLocales () {
			this.languageOptions.forEach(el => this.localeObjectArray.push({ 'name': el.toUpperCase(), 'value': el }));
		},
		initGetApp () {
			if(this.project == 'eKool') {
				this.showGetApp = true;
			}
			//detect users device
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;

			if (/android/i.test(userAgent)) {
				this.device = "android"
				this.appLink = "https://play.google.com/store/apps/details?id=eu.ekool.app&hl=en&gl=US"
				this.imageSrc = require(`../assets/google-play.png`)
			}

			if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				this.device = "ios"
				this.appLink = "https://apps.apple.com/ee/app/mkool/id890813955"
				this.imageSrc = require(`../assets/app-store.png`)
			}
		},
	},
};
</script>
