import Vue from 'vue'
import Vuex from 'vuex'

import * as api from '../../api'
//import store from '..'

Vue.use(Vuex)

export default{
	state: {
		xidValidationBusy: false,
		xidValidationErrors: undefined,
		xidValid: false,
	},
	mutations: {
		//token validation to be added..
        
		//password reset
		xidValidation (state){
			state.xidValidationBusy = true;
		},
		xidValidationSuccess (state,response){
			state.xidValidationBusy = false;
			if (response.body.success && response.body.user) {
				state.xidValid = true;
			}else{
				state.xidValid = false;
			}
		},
		xidValidationFail (state,error){
			state.xidValidationBusy = false;
			state.xidValid = false;
			if(error.body.errors){
				state.xidValidationErrors = error.body.errors;
			}
		},
		
	},
	actions: {
		xidValidation: (store) => {
			store.commit('xidValidation')
			return api.getXidData()
				.then(
					response => {
						store.commit('xidValidationSuccess',response)
						return response;
					},
					error => {
						store.commit('xidValidationFail', error)
						return error;
					}
				)
		},
	},
	getters: {
		xidValidationBusy: state => state.xidValidationBusy,
		xidValidationErrors: state => state.xidValidationErrors,
		xidValid: state => state.xidValid,
	},
}