<template>
	<div class="view-content__wrapper">
		<div class="view-content d-block d-md-flex align-center justify-center mx-auto">
			<div>
				<div
					v-if="type == 'registration'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							{{ $vuetify.theme.dark ? 'icon-paper-plane grayscale__text' : 'icon-paper-plane graybase--text' }}	
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("registration_successful.activation_link_sent") }}
					</p>
					<p
						v-if="email !== undefined"
						class="body-3 text-center"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
						<span>
							{{ $t("registration_successful.account_created") }} -
						</span>
						<a
							class="success--text"
							:href="'mailto:' + email">{{ email }}</a>
					</p>
					<p
						v-if="email == undefined"
						class="body-3 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale500--text'">
						{{ $t("registration_successful.account_created") }}
					</p>
				</div>
				<div
					v-if="type == 'registrationMessage'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-paper-plane success--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("registration_successful." + message) }}
					</p>
				</div>
				<div
					v-if="type == 'school'"
					class="registration">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-success success--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("school_registration.school_registered") }}
					</p>
				</div>
				<div
					v-if="type == 'reset' || type == 'mykoobReset' || type == 'mykoobAdminReset'"
					class="password-reset">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-success success--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("password_recovery.password_changed_successfuly") }}
					</p>
				</div>
				<div
					v-if="type == 'resetRequest'"
					class="password-reset">
					<div class="text-center mb-6">
						<v-icon size="70">
							{{ $vuetify.theme.dark ? 'icon-paper-plane grayscale100--text' : 'icon-paper-plane graybase--text' }}
						</v-icon>
					</div>
					
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("password_recovery.link_sent") }}
					</p>
					<div class="text-center">
						<e-button
							class="mb-2"
							variant="secondary"
							medium=""
							:to="'/' + $i18n.locale + '/forgotPassword'"
							:block="false">
							{{ $t('forgot_password.resend_email') }}
						</e-button>
					</div>
				</div>
				<div
					v-if="type == 'activation'"
					class="password-reset">
					<div class="text-center mb-6">
						<v-icon size="70">
							icon-success success--text
						</v-icon>
					</div>
					<p
						class="headline-4 text-center mx-10"
						:class="$vuetify.theme.dark ? 'grayscale100--text' : 'graybase--text'">
						{{ $t("registration_successful.account_activated") }}
					</p>
				</div>
				<div
					class="text-center mt-4">
					<e-button
						v-if="type !== 'mykoobReset' && type !== 'mykoobAdminReset'"
						variant="link"
						small=""
						:block="false"
						@click="toLogin()">
						{{ $t("forgot_password.back_to_frontpage") }}
					</e-button>
					<e-button
						v-if="type == 'mykoobReset'"
						variant="link"
						small=""
						:block="false"
						:href="mainUrl">
						{{ $t("forgot_password.back_to_frontpage") }}
					</e-button>
					<e-button
						v-if="type == 'mykoobAdminReset'"
						variant="link"
						small=""
						:block="false"
						@click="closeWindow()">
						{{ $t("forgot_password.back_to_frontpage") }}
					</e-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getEnv } from "../env";
export default {
	data () {
		return {
			email: undefined,
			type: undefined,
			message: "",
			mainUrl: getEnv('VUE_APP_FRONTEND_APP_URL'),
		};
	},
	mounted () {
		this.email = this.$route.params.email;
		this.message = this.$route.params.message;

		if(this.$route.params.type){
			this.type = this.$route.params.type;
		}

		else if(this.$route.query.type){
			this.type = this.$route.query.type;
		}
		
		if(this.type == undefined){
			this.$router.replace({
				name: "login",
			});
		}
	},
	methods: {
		closeWindow () {
			window.open("", "_self");
			window.close();   
		},
		toLogin () {
			this.$router.push('/' + this.$i18n.locale)
		},
	},
};
</script>

<style lang="scss">
//@import "../assets/styles.scss";
</style>
