<template>
	<div class="d-flex flex-column align-center">
		<span
			class="body-2 text-center"
			:class="$vuetify.theme.dark ? 'grayscale400--text' : 'grayscale600--text'">
			{{ $t('two_factor.trust_device_description') }}
		</span>
		<div
			class="d-flex align-center justify-content-center mt-8 g-4"
			style="width: 100%; gap: 1.6rem;">
			<e-button
				style="flex: 0 50%;"
				variant="tertiary"
				size="large"
				large
				@click="proceed(true)">
				{{ $t('two_factor.trust_this_device') }}
			</e-button>
			<e-button
				style="flex: 0 50%;"
				variant="primary"
				size="large"
				large
				@click="proceed(false)">
				{{ $t('two_factor.dont_trust') }}
			</e-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
	computed: {
		...mapState([
			'mobileView',
		]),
		...mapGetters([
			'twoFactorRememberBusy',
			'twoFactorRememberSuccess',
			'twoFactorRememberError',
			'twoFactorRememberErrors',
			'rememberRequestRequired',
			'termsRequired',
		]),
	},
	methods: {
		proceed (val){
			const remember = val
			this.$store.dispatch('twoFactorRemember', { remember }).then(
				response => {
					if(this.termsRequired){
						this.$router.push({
							name: "agreeToTerms",
						});
					}
					if(this.twoFactorRememberSuccess && !this.termsRequired){
						if (this.miniView) {
							this.postLoginMessage('success')
							return
						}
						window.top.location.href = response.body.url;
					}
					else if(this.twoFactorRememberError && this.twoFactorRememberErrors == 'user_not_found'){
						this.$router.push({
							name: "login",
						});
					}else if(this.twoFactorRememberError && this.twoFactorRememberErrors !== undefined){
						this.$eToast.error(this.$i18n.t(`password_login.${this.twoFactorErrors}`));
					}else if(this.twoFactorRememberErrors){
						this.$eToast.error(this.$i18n.t(`password_login.submit_error`));
					}
				},

			)
		},
	},
};
</script>
