import { getEnv } from "../env"

export async function postLoginMessage (status) {
	if (window.parent) {
		await fetch(getEnv('VUE_APP_FRONTEND_APP_URL') + '/set-user?redirect=false')
		var dataObject = {
			action: 'login',
			type: status,
		}
		window.parent.postMessage(dataObject, '*', [])
	}
}

